import axios from 'axios';
import isString from 'lodash/isString';
import replace from "lodash/replace";
import merge from "lodash/merge";

// eslint-disable-next-line no-unused-vars
import fakeData from "src/services/fakeData";
import apiDefinition from 'src/config/apiDefinition';
import urlService from 'src/services/urlService';
import log from 'src/services/logger';
const logTag = "apiService";

// the main api resource instance, to which we can attach interceptors and use it for API calls
export const apiResource = axios.create({
    baseURL : config.api.base + config.api.endpoint,
    headers : {
        'Content-Type'  : 'application/json'
    }
});

// ####### Auth operations #########
export const login = function({ email, password }) {
    log.log(logTag, "Login");
    return apiResource.post(apiDefinition.login.base, { email, password });
};

export const ssoLogin = function({ token }) {
    log.log(logTag, "SSO login");
    return apiResource.post(apiDefinition.login.base + apiDefinition.login.token, { token });
};

export const register = function({ email, password }) {
    log.log(logTag, "Register");
    return apiResource.post(apiDefinition.register, { email, password });
};

export const activate = function({ email, token }) {
    log.log(logTag, "Activate");
    return apiResource.post(apiDefinition.activate, { email, token });
};

export const changePass = function({ old_password, new_password }) {
    return apiResource.post(apiDefinition.resetPassword, { old_password, new_password });
};

export const resetForgottenPass = function({ email, password, token }) {
    return apiResource.post(apiDefinition.resetPassword, {
        email, new_password : password, reset_token : token
    });
};

export const logout = function() {
    return apiResource.post(apiDefinition.logout);
};

export const forgottenPass = function({ email }) {
    return apiResource.post(apiDefinition.forgotten, { email });
};

export const setAuthTokenToHeaders = function(token) {
    if (isString(token)) {
        log.log(logTag, "Setting authorization token to HTTP headers");
        apiResource.defaults.headers.common['Authorization'] = "Bearer " + token;
        return Promise.resolve(token);
    } else {
        log.log(logTag, "Auth token is empty");
        return Promise.reject("Auth token is empty");
    }
};

export const unsetAuthTokenFromHeaders = function() {
    log.log(logTag, "Removing authorization token from HTTP headers");
    delete apiResource.defaults.headers.common['Authorization'];
};

// ####### Areas, focus category #########
export const getAreas = function() {
    log.log(logTag, "Getting areas");
    return apiResource.get(apiDefinition.areas);
};

export const focusCategory = function(categoryId) {
    const apiUrl = apiDefinition.categories.base + replace(apiDefinition.categories.focus, "{id}", categoryId);
    return apiResource.post(apiUrl);
};

// ####### Area token - added with Multiple areas feature 3/2023. 
// since 12/2023: this is also used if single team area is used, i.e. for iHrdinove
export const setAreaIdToHeaders = function(areaId) {
    log.log(logTag, "Setting area ID to HTTP headers: " + areaId);
    apiResource.defaults.headers.common['x-ifo-area'] = areaId;
};

export const postPreferredArea = function(areaId) {
    log.log(logTag, "Posting preferred area: " + areaId);
    return apiResource.post(
        apiDefinition.user.base + apiDefinition.user.preferredArea,
        { area_id : areaId }
    );
};

// ####### User Data #########
export const getUserData = function() {
    return apiResource.get(apiDefinition.user.base);
};


// ####### Items #########
export const drawItem = function() {
    // 10/24: if category ID is in URL, send it together with drawItem request
    const categoryId = urlService.getCategoryId();
    log.log(logTag, `Drawing an item. Category ID: ${categoryId}`);
    return apiResource.get(
        apiDefinition.items.base + apiDefinition.items.draw, 
        { params : { category_id : categoryId } }
    );
};

export const postponeItem = function(itemId) {
    return apiResource.post(apiDefinition.items.base + replace(apiDefinition.items.postpone, "{id}", itemId));
};

export const answerItem = function(itemId, answer) {
    log.log(logTag, "answering");
    log.log(logTag, answer);
    return apiResource.post(
        apiDefinition.items.base + replace(apiDefinition.items.answer, "{id}", itemId),
        { answer }
    );
};

// ####### Achievements #########
export const getAchievements = function() {
    return apiResource.get(apiDefinition.achievements);
};

// ####### Leaderboard #########
export const getTeamUsersLeaderboard = function() {
    return apiResource.get(apiDefinition.teamUsersLeaderboard);
};

export const getAreaTeamsLeaderboard = function() {
    return apiResource.get(apiDefinition.areaTeamsLeaderboard);
};

export const getAllUsersLeaderboard = function() {
    return apiResource.get(apiDefinition.allUsersLeaderboard);
};

export const getAreaBattlesLeaderboard = function() {
    return apiResource.get(apiDefinition.areaBattlesLeaderboard);
};

export const getTeamBattlesLeaderboard = function() {
    return apiResource.get(apiDefinition.teamBattlesLeaderboard);
};


// ####### Notifications #########
export const getNotifications = function() {
    return apiResource.get(apiDefinition.notifications, { doNotToggleLoader : true });
};

// ####### Battles #########
export const getBattles = function() {
    return apiResource.get(apiDefinition.battles.base);
};

export const postBattleChallenge = function(opponentId) {
    return apiResource.post(apiDefinition.battles.base, { opponent : opponentId });
};

export const postAcceptBattle = function(battleId) {
    return apiResource.post(
        apiDefinition.battles.base + replace(apiDefinition.battles.accept, "{id}", battleId)
    );
};

export const postDeclineBattle = function(battleId) {
    return apiResource.post(
        apiDefinition.battles.base + replace(apiDefinition.battles.decline, "{id}", battleId)
    );
};

export const postBattleQuestionAnswer = function(battleId, itemId, answer) {
    return apiResource.post(
        apiDefinition.battles.base + replace(apiDefinition.battles.answer, "{id}", battleId),
        { id : itemId, answer }
    );
};


// ####### Knowledge Test #########
export const getKnowledgeTests = function() {
    return apiResource.get(apiDefinition.tests.base);
};

export const startTestAttempt = function(testId, attemptId) {
    return apiResource.post(apiDefinition.tests.base + replace(
        apiDefinition.tests.startAttempt, "{testId}/{id}", testId + "/" + (attemptId ? attemptId : "")
    ));
};

export const getTestAttemptInfo = function(attemptId) {
    return apiResource.get(
        apiDefinition.tests.base + replace(apiDefinition.tests.attempt, "{id}", attemptId)
    );
};

export const finishTestAttempt = function(attemptId) {
    return apiResource.post(
        apiDefinition.tests.base + replace(apiDefinition.tests.finishAttempt, "{id}", attemptId)
    );
};

export const getTestSyncTime = function(attemptId) {
    return apiResource.get(
        apiDefinition.tests.base + replace(apiDefinition.tests.syncTime, "{id}", attemptId),
        { doNotToggleLoader : true }
    );
};

export const postTestAnswer = function({ id, answer }) {
    return apiResource.post(
        apiDefinition.tests.base + apiDefinition.tests.answer,
        {
            id : id,
            answer : answer
        }
    );
};

export const postTestVideoWatched = function(attemptId) {
    return apiResource.post(
        apiDefinition.tests.base + replace(apiDefinition.tests.videoWatched, "{id}", attemptId)
    );
};

const fakeApiService = {
    // ####### Knowledge Tests #########
    // getKnowledgeTests : () => fakeData.getKnowledgeTests(),
    // startTestAttempt : (testId, attemptId) => fakeData.startTestAttempt(testId, attemptId),
    // getTestAttemptInfo : (attemptId) => fakeData.getTestAttemptInfo(attemptId),
    // finishTestAttempt : (attemptId) => fakeData.finishTestAttempt(attemptId),
    // getTestSyncTime : (attemptId) => fakeData.getTestSyncTime(attemptId),
    // postTestAnswer : ({ id, answer }) => fakeData.postTestAnswer({ id, answer }),
    // postTestVideoWatched : (attemptId) => fakeData.postTestVideoWatched(attemptId),
};

// the real api service is being merged with fakeApiService - to be able to overwrite any real methods with
// fake ones for testing
const combinedApiService = merge({
    // ####### Auth operations #########
    login,
    ssoLogin,
    logout,
    register,
    activate,
    changePass,
    resetForgottenPass,
    forgottenPass,

    setAuthTokenToHeaders,
    unsetAuthTokenFromHeaders,

    // ####### Areas, focus category #########
    focusCategory,
    getAreas,

    // ####### Multiple areas feature 3/2023 #########
    setAreaIdToHeaders,

    // ####### User Data #########
    getUserData,
    postPreferredArea,

    // ####### Items #########
    drawItem,
    postponeItem,
    answerItem,

    // ####### Achievements #########
    getAchievements,

    // ####### Leaderboard #########
    getTeamUsersLeaderboard,
    getAreaTeamsLeaderboard,
    getAllUsersLeaderboard,
    getAreaBattlesLeaderboard,
    getTeamBattlesLeaderboard,

    // ####### Notifications #########
    getNotifications,

    // ####### Battles #########
    getBattles,
    postBattleChallenge,
    postAcceptBattle,
    postDeclineBattle,
    postBattleQuestionAnswer,

    // ####### Knowledge Tests #########
    getKnowledgeTests,
    startTestAttempt,
    getTestAttemptInfo,
    finishTestAttempt,
    getTestSyncTime,
    postTestAnswer,
    postTestVideoWatched
}, fakeApiService);

export default combinedApiService;
