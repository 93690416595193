import * as types from "./mutationTypes";
import store from 'src/store/store';
import apiService from "src/services/api/apiService";
import authenticationService from 'src/services/api/authenticationService';
// import log from "src/services/logger";
// const logTag = "store.auth";

const moduleState = {
    isAuthenticated     : false,
    authToken           : ""
};

const moduleGetters = {
    isAuthenticated     : state => state.isAuthenticated,
    authToken           : state => state.authToken
};

const actions = {
    sendLoginRequest(_, loginData) {
        return authenticationService.login(loginData).then(loginResult => {
            // user is now logged in. Wait for this - needed for Area Chooser feature, harmless otherwise.
            return store.dispatch("afterLogin", loginResult.data.token).then(() => loginResult);
        });
    },

    sendSsoLoginRequest(_, loginData) {
        return authenticationService.ssoLogin(loginData).then(ssoResult => {
            // user is now logged in. Wait for this and then return the original ssoLogin api call response.
            return store.dispatch("afterLogin", ssoResult.data.token).then(() => ssoResult);
        });
    },

    afterLogin({ commit }, token) {
        commit(types.SET_AUTH_DATA, token);

        // pull user info and wait for it - it also contains preferred_area_id which is then set into
        // HTTP header in Area Chooser feature.
        // Change 10/2024: we're waiting for downloadAreas now, to enable "area ID in URL" feature.
        return store.dispatch("downloadAreas")
            .then(() => store.dispatch("downloadUserData"))
            .then(() => store.dispatch("startNotificationPolling"));
    },

    sendLogoutRequest() {
        return authenticationService.logout().then((result) => {
            store.dispatch("doLocalLogout");
            return result;
        });
    },

    doLocalLogout({ commit }) {
        store.dispatch("stopNotificationPolling");
        authenticationService.handleTokenRemoval();
        commit(types.UNSET_AUTH_DATA);
    },

    sendRegistrationRequest(_, registrationData) {
        // @note: a useless action? apiService can be called directly
        return authenticationService.register(registrationData);
    },

    sendActivateRequest(_, activationData) {
        return authenticationService.activate(activationData).then(activateResult => {
            // user is now logged in. Wait for this - needed for Area Chooser feature, harmless otherwise.
            return store.dispatch("afterLogin", activateResult.data.token).then(() => activateResult);
        });
    },

    sendForgottenPassRequest(_, data) {
        return apiService.forgottenPass(data);
    },

    sendResetPassRequest({ commit }, passData) {
        return authenticationService.resetForgottenPass(passData).then(resetResult => {
            // user is now logged in. Wait for this - needed for Area Chooser feature, harmless otherwise.
            return store.dispatch("afterLogin", resetResult.data.token).then(() => resetResult);
        });
    },

    sendChangePassRequest({ commit }, passData) {
        return authenticationService.changePass(passData)
        .then(result => {
            commit(types.SET_AUTH_DATA, result.data.token);
            return result;
        });
    },
};

const mutations = {
    [types.SET_AUTH_DATA](state, token) {
        state.authToken = token;
        state.isAuthenticated = true;
    },
    [types.UNSET_AUTH_DATA](state) {
        state.authToken = "";
        state.isAuthenticated = false;
    },
};

export default {
    state : moduleState,
    getters : moduleGetters,
    actions,
    mutations
};
