var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "item-view" }, [
    _vm.isThereAnyItem
      ? _c("div", [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-sm-3" }, [
              !_vm.currentItem || !_vm.currentItem.image_path
                ? _c("div", { staticClass: "bg-img img-responsive" })
                : _c("img", {
                    staticClass: "img img-responsive",
                    attrs: {
                      src: _vm.currentItem.image_path,
                      alt: _vm._f("translate")("ALT_IMAGE_ITEM"),
                    },
                  }),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-sm-9" },
              [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    {
                      staticClass: "col-xs-12 col-sm-8 col-lg-9",
                      class: _vm.showOnboardingLink
                        ? "col-sm-8 col-lg-9"
                        : "col-sm-12 col-lg-12",
                    },
                    [_c("h3", [_vm._v(_vm._s(_vm.currentItem.name))])]
                  ),
                  _vm._v(" "),
                  _vm.showOnboardingLink
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-4 col-lg-3 onboarding-link-wrapper",
                        },
                        [
                          _c("i", { staticClass: "fa fa-question-circle-o" }),
                          _vm._v(" "),
                          _c("a", { attrs: { href: "#/onboarding" } }, [
                            _vm._v(
                              "\n                            " +
                                _vm._s(_vm._f("translate")("TO_ONBOARDING")) +
                                "\n                        "
                            ),
                          ]),
                        ]
                      )
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-xs-12 col-sm-4 points-wrapper" },
                    [
                      _vm._v(
                        "\n                        " +
                          _vm._s(
                            _vm.$t("POINTS_GAINED", {
                              points: _vm.currentItem.correct_points,
                            })
                          ) +
                          "\n                    "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.showCategoryInfo
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-8 item-category-wrapper",
                        },
                        [
                          _c("span", { staticClass: "area-title" }, [
                            _vm._v(
                              _vm._s(_vm._f("translate")("ITEM_CATEGORY")) + ":"
                            ),
                          ]),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.currentItemCategory
                                  ? _vm.currentItemCategory.name
                                  : ""
                              ) +
                              "\n                    "
                          ),
                        ]
                      )
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("p", {
                  staticClass: "item-description",
                  domProps: { innerHTML: _vm._s(_vm.currentItem.description) },
                }),
                _vm._v(" "),
                _vm.currentItem.description_image_path
                  ? _c("img", {
                      directives: [{ name: "img", rawName: "v-img" }],
                      staticClass: "img img-responsive description-img",
                      attrs: {
                        src: _vm.currentItem.description_image_path,
                        alt: _vm._f("translate")("ALT_DESCRIPTION_IMAGE_ITEM"),
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  _vm.currentItem.type === "text"
                    ? "TextAnswer"
                    : "ChoiceAnswer",
                  {
                    ref: "answerComponent",
                    tag: "component",
                    attrs: { item: _vm.currentItem },
                    on: { answer: _vm.answer },
                  },
                  [
                    _c("answer-button-row", {
                      attrs: { slot: "buttons" },
                      on: { postpone: _vm.postpone },
                      slot: "buttons",
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.showFocusInfo
                  ? _c("div", { staticClass: "row focus-row" }, [
                      _c("div", { staticClass: "col-xs-12" }, [
                        _vm.isAnyCategoryFocused
                          ? _c("span", [
                              _c("span", { staticClass: "focus-title" }, [
                                _vm._v(
                                  _vm._s(_vm._f("translate")("YOUR_FOCUS_IS")) +
                                    ":"
                                ),
                              ]),
                              _vm._v(
                                " " +
                                  _vm._s(_vm.currentFocusedCategory.name) +
                                  "\n                        "
                              ),
                            ])
                          : _c("span", { staticClass: "focus-title" }, [
                              _vm._v(
                                _vm._s(_vm._f("translate")("YOU_HAVE_NO_FOCUS"))
                              ),
                            ]),
                        _vm._v(" "),
                        !_vm.isAnyCategoryFocused ||
                        _vm.currentCategories.length > 1
                          ? _c(
                              "a",
                              {
                                staticClass: "focus-link",
                                attrs: { href: "#/focus" },
                              },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(
                                      _vm._f("translate")("TO_FOCUS_CHANGE")
                                    ) +
                                    "\n                        "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]),
                    ])
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }