<template>
    <div id="focus" class="page">
        <div class="part-1"
             v-if="showUserProgressOnTop"
        >
            <div class="container">
                <user-progress :stats="currentProgress"
                               showProgressbar :progressbarValue="currentAreaLevelProgress"
                ></user-progress>
            </div>
        </div>

        <div class="part-2">
            <div class="container">
                <h1>{{ currentAreaDetails.focus_main_title }}</h1>
                <h2>{{ currentAreaDetails.focus_main_subtitle }}</h2>
                <h3>{{ currentAreaDetails.focus_main_text }}</h3>

                <div class="row categories-row">
                    <!-- First, let's list all the currently open knowledge tests -->
                    <knowledge-test-list v-if="knowledgeTestsEnabled"></knowledge-test-list>

                    <!-- Knowledge tests are followed by regular "categories" -->
                    <div class="col-sm-6 col-md-4"
                         v-for="category in currentCategories" :key="category.id"
                    >
                        <div class="category-box" :class="{ 'focused-category' : isFocused(category.id) }">
                            <div data-key="sameHeightCategories">
                                <img :src="category.image_path"
                                     class="img img-responsive"
                                     :alt="'ALT_IMAGE_CATEGORY' | translate"
                                >

                                <div class="content-wrapper">
                                    <h3>
                                        {{ category.name }}
                                        <template v-if="category.highlighted">
                                            <span class="label label-important" v-if="category.highlight_tag"
                                            >{{ category.highlight_tag }}</span>
                                            <span class="label label-important" v-else
                                            >{{ "FOCUS_HIGHLIGHTED_CATEGORY" | translate }}</span>
                                        </template>
                                    </h3>

                                    <p class="category-description">
                                        {{ category.description }}
                                    </p>
                                </div>
                            </div>
                            <button class="btn secondary-btn"
                                    @click="focus(category.id)"
                                    :disabled="isFocused(category.id) && disableFocusedCtaBtn"
                            >
                                <span v-if="isFocused(category.id)">{{ 'FOCUS_CHOSEN_CATEGORY' | translate }}</span>
                                <span v-else>{{ 'FOCUS_CHOOSE_CATEGORY' | translate }}</span>
                            </button>
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-4"
                         v-if="showFocusNoneTile"
                    >
                        <div class="category-box no-focus" :class="{ 'focused-category' : isFocused(0) }">
                            <div data-key="sameHeightCategories">
                                <!-- this is different than img-responsive! -->
                                <div class="bg-img responsive-bg-img"></div>

                                <div class="content-wrapper">
                                    <h3>{{ 'FOCUS_NONE_TITLE' | translate }}</h3>
                                    <p class="category-description">
                                        {{ currentAreaDetails.focus_none_text }}
                                    </p>
                                </div>
                            </div>
                            <button class="btn secondary-btn"
                                    @click="focus(0)"
                                    :disabled="isFocused(0) && disableFocusedCtaBtn"
                            >
                                {{ 'FOCUS_CHOOSE_CATEGORY' | translate }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="part-1"
             v-if="!showUserProgressOnTop"
        >
            <div class="container">
                <user-progress :stats="currentProgress"
                               showProgressbar :progressbarValue="currentAreaLevelProgress"
                ></user-progress>
            </div>
        </div>

    </div>
</template>
<!---------------------------------------------------------------------------->
<script>
    import { mapGetters } from "vuex";
    import sameHeight from "src/services/sameHeight";

    import UserProgress from "Components/common/UserProgress";
    import KnowledgeTestList from "./KnowledgeTestList";
    import userMessages from "src/services/userMessages";

    export default {
        components : { UserProgress, KnowledgeTestList },

        created : function() {
            this.$store.dispatch("downloadAreas").catch(() => {});
        },

        data() {
            return {
                showFocusNoneTile       : config.features.focusNoneAllowed,
                knowledgeTestsEnabled   : config.features.knowledgeTests,
                showUserProgressOnTop   : config.features.showUserProgressOnTop,
                disableFocusedCtaBtn    : config.features.disableFocusedCategoryCtaBtn
            };
        },

        computed : mapGetters([
            "currentArea", "currentCategories", "currentFocusedCategories", "currentProgress",
            "currentAreaDetails", "currentAreaLevelProgress"
        ]),

        mounted : function() {
            setTimeout(this.equalizeHeights, 500);
            setTimeout(this.equalizeHeights, 1200);
            window.addEventListener('resize', this.equalizeHeights);
        },

        destroyed : function() {
            window.removeEventListener('resize', this.equalizeHeights);
        },

        methods : {
            isFocused(categoryId) {
                if (!this.currentFocusedCategories) {
                    return false;
                }
                return this.currentFocusedCategories.indexOf(categoryId) !== -1;
            },

            focus(categoryId) {
                // send the focus data do server
                this.$store.dispatch("focusCategory", categoryId)

                // go to item dashboard, show success message
                .then((response) => {
                    this.$store.dispatch("downloadAreas"); // @note: probably not needed
                    this.$store.dispatch("showSuccessMessage", userMessages.afterFocusCategory(response));
                    this.$router.push("/item-dashboard");
                })

                // changing of focus failed, show the error
                .catch(reason => {
                    this.$store.dispatch("showErrorMessage", userMessages.afterFocusCategory(reason));
                });
            },

            equalizeHeights() {
                sameHeight.equalize("[data-key='sameHeightCategories']");
            }
        }
    };
</script>
<!---------------------------------------------------------------------------->
<style lang="scss" type="text/scss">
</style>
