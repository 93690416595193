
import log from "src/services/logger";
const logTag = "urlService";


const setSearchParamsToUrl = (searchParams) => {
    window.history.pushState(
        {}, '', `${window.location.pathname}${searchParams ? "?" + searchParams : ''}${window.location.hash}`
    );
}

export const getAreaId = () => {
    const areaId = parseInt((new URLSearchParams(window.location.search)).get('a'), 10);
    return isNaN(areaId) ? null : areaId;
}

export const setAreaId = (areaId, setEventIfNoParamsInUrl) => {
    if (!window.location.search && !setEventIfNoParamsInUrl) {
        return;
    }

    log.log(logTag, `Setting area ID: ${areaId}`);

    const searchParams = new URLSearchParams(window.location.search);
    
    if (areaId) {
        searchParams.set('a', areaId);

    // receiving e.g. undefined as areaId will remove the "a" param from the URL
    } else {
        searchParams.delete('a');
    }

    setSearchParamsToUrl(searchParams.toString());
}


export const getCategoryId = () => {
    const categoryId = parseInt((new URLSearchParams(window.location.search)).get('c'), 10);
    return isNaN(categoryId) ? null : categoryId;
}

export const setCategoryId = (categoryId, setEventIfNoParamsInUrl) => {
    if (!window.location.search && !setEventIfNoParamsInUrl) {
        return;
    }

    log.log(logTag, `Setting category ID: ${categoryId}`);

    const searchParams = new URLSearchParams(window.location.search);
    
    if (categoryId) {
        searchParams.set('c', categoryId);

    // receiving e.g. undefined as categoryId will remove the "c" param from the URL
    } else {
        searchParams.delete('c');
    }

    setSearchParamsToUrl(searchParams.toString());
}


export const storeUrlParams = () => {
    if (!window.location.search) {
        return;
    }

    log.log(logTag, `Storing URL params: ${window.location.search}`);
    localStorage.setItem("urlSearchParams", JSON.stringify(window.location.search));
}

export const restoreUrlParams = () => {
    const storedUrlParams = JSON.parse(localStorage.getItem("urlSearchParams"));

    if (!storedUrlParams) {
        return;
    }

    log.log(logTag, `Restoring URL params: ${storedUrlParams}`);

    localStorage.removeItem("urlSearchParams");
    
    // set the URL search params back to the URL
    setSearchParamsToUrl(storedUrlParams);
}

export const removeUrlParams = () => {
    log.log(logTag, "Removing URL params");
    setSearchParamsToUrl("");
}

export default {
    getAreaId,
    setAreaId,
    getCategoryId,
    setCategoryId,

    storeUrlParams,
    restoreUrlParams,
    removeUrlParams
}
