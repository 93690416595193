<template>
    <div id="not-found" class="page">
        <div class="part-1">
            <div class="container">
                <h1>{{ 'NOT_FOUND_MAIN_TITLE' | translate }}</h1>
                <p class="text-center">
                    {{ 'NOT_FOUND_MAIN_TEXT' | translate }}
                </p>
                <a class="btn brand-secondary-btn" href="#/">
                    {{ 'NOT_FOUND_REDIRECT_BUTTON' | translate }}
                </a>
            </div>
        </div>
    </div>
</template>
<!---------------------------------------------------------------------------->
<script>
    export default {};
</script>
<!---------------------------------------------------------------------------->
<style lang="scss" rel="stylesheet/scss">
</style>