const translationsSk = {
    // Navigation
    "LOGIN"                             : "Prihlás sa",
    "LOGOUT"                            : "Odhlásiť",
    "POINTS_COUNT"                      : "Body",
    "REGISTRATION"                      : "Registrácia",

    // Footer
    "CREATED_BY"                        : "Made by",
    "FOR_MORE_INFO_CONTACT"             : "Pre viac informácií kontaktujte",
    "FOOTER_RIGHT_TEXT"                 : "Táto stránka používa cookies",

    // page Login - texts above the form
    "LOGIN_MAIN_TITLE"                  : "Vitaj v Akadémii budúcnosti",
    "LOGIN_MAIN_SUBTITLE"               : "Slovenská sporiteľňa je tu viac ako 100 rokov. My sme tu na to, aby tu bola ešte ďalších 100.",
    "LOGIN_SECONDARY_TITLE"             : "Čo je Akadémia budúcnosti?",
    "LOGIN_SECONDARY_SUBTITLE"          : "Sme vzdelávacia platforma moderného typu pre ľudí ako ty.",
    "LOGIN_EXPLANATION_FIRST_TITLE"     : "Dôležité témy",
    "LOGIN_EXPLANATION_FIRST_TEXT"      : "Nájdeš tu témy, ktorými Sporka žije a ktoré bude potrebovať na prežitie. ",
    "LOGIN_EXPLANATION_SECOND_TITLE"    : "Časová efektivita",
    "LOGIN_EXPLANATION_SECOND_TEXT"     : "Nestrať sa v trendoch a buď v obraze. Stačia ti na to 3 minúty denne.",
    "LOGIN_EXPLANATION_THIRD_TITLE"     : "Hravá forma",
    "LOGIN_EXPLANATION_THIRD_TEXT"      : "Objavuj svoje skryté kompetencie a ukáž ostatným, čo v tebe je.",
    "LOGIN_SECTION_TITLE"               : "Nauč sa dnes veci, ktoré budeš potrebovať zajtra",
    "LOGIN_SECTION_SUBTITLE"            : "Rozvíjaj svoje schopnosti a potvrď všetkým, že ty si budúcnosť.",

    // page Login -> Login form component
    // @note: not used in this variant
    "LOGIN_TITLE"                       : "Kto našiel odvahu vstúpiť na túto cestu?",
    "LOGIN_BUTTON"                      : "Prihlás sa",
    "EMAIL"                             : "Email",
    "PASSWORD"                          : "Heslo",
    "LOGIN_FAILED_CREDENTIALS"          : "Nesprávne prihlasovacie údaje!",
    "LOGIN_FAILED_ERROR"                : "Nastala chyba. Skús to ešte raz",
    "LOGIN_FAILED_NOT_FOUND"            : "Nastala chyba. Skús to ešte raz. Ak sa chyba opakuje, prosíme o nahlásenie",
    "LOGIN_SUCCESSFUL"                  : "Si prihlásený!",

    // page Login -> Register form component
    // @note: not used in this variant
    "REGISTER"                          : "Ešte nemáš konto? Zaregistruj sa",
    "REGISTER_BUTTON"                   : "Odoslať",
    "NAME"                              : "Meno",
    "REGISTRATION_SUCCESSFUL"           : "Registrace proběhla úspěšně. Na tvůj email jsme odeslali aktivační link.",
    "REGISTRATION_FAILED_ERROR"         : "Nastala chyba. Skús to ešte raz",
    "REGISTRATION_FAILED_VALIDATION"    : "Nesprávne údaje",
    "BACK"                              : "Spät",

    // after user clicks on activation link in email
    // @note: not used in this variant
    "ACTIVATION_SUCCESSFUL"             : "Aktivace proběhla úspěšně! Jsi přihlášen.",
    "ACTIVATION_FAILED_NOT_FOUND"       : "Aktivace selhala - užívatel neexistuje!",
    "ACTIVATION_FAILED_VERIFIED"        : "Aktivace selhala - tenhle užívatel je už aktivní!",
    "ACTIVATION_FAILED_VALIDATION"      : "Aktivace selhala - nesprávný aktivační link!",
    "ACTIVATION_FAILED_ERROR"           : "Aktivace selhala!",

    // page Login -> Forgotten password
    // @note: not used in this variant
    "FORGOTTEN_PASSWORD_TITLE"          : "Zapomenuté heslo",
    "FORGOTTEN_PASSWORD"                : "Zabudol si svoje heslo?",
    "FORGOTTEN_PASS_SUBMIT"             : "Odoslať",
    "FORGOTTEN_PASS_SUCCESS"            : "Na tvůj email jsme odeslali aktivační link.",
    "FORGOTTEN_PASS_FAILED_ERROR"       : "Nastala chyba. Skús to ešte raz",
    "FORGOTTEN_PASS_FAILED_VALIDATION"  : "Tento email nemáme v databázi!",

    // page Reset Password
    // @note: not used in this variant
    "RESET_PASS_MAIN_TITLE"             : "I superhrdinové občas potřebují pomoc!",
    "RESET_PASS_MAIN_SUBTITLE"          : "Zdá se že máš problém se svým heslem? Nevadí, pomůžeme ti.",
    "RESET_PASS_SECONDARY_TITLE"        : "Teď si můžeš vytvořit nové, neprůstřelné heslo.",
    "RESET_PASS_TITLE"                  : "Resetovat heslo",
    "RESET_PASS_BUTTON"                 : "Resetovat",
    "RESET_PASS_SUCCESSFUL"             : "Tvoje heslo bylo úspěšně změněno",
    "RESET_PASS_FAILED_ERROR"           : "Nastala chyba. Skús to ešte raz",
    "NEW_PASSWORD"                      : "Nové heslo",

    // page Profile - texts on top
    "PROFILE_MAIN_TITLE"                : "Ako sa ti darí?",
    "PROFILE_MAIN_SUBTITLE"             : "Prehľad tvojich úspechov",

    // page Profile - areas' progress texts
    "PROFILE_CONTINUE"                  : "Pokračovať",
    "PROFILE_AREA_FINISHED"             : "Toto dobrodružstvo už skončilo",

    // page Profile - texts above profile edit fields (change password etc)
    "PROFILE_EDIT_MAIN_TITLE"           : "Tvoje údaje",

    // page Profile -> Change Password
    // @note: not used in this variant
    "CHANGE_PASS_TITLE"                 : "Zmena hesla",
    "CHANGE_PASS_SUBMIT"                : "Zmeniť",
    "CHANGE_PASS_SUCCESS"               : "Tvoje heslo bolo úspešne zmenené",
    "CHANGE_PASS_FAILED_UNAUTHORIZED"   : "Nejsi přihlášen!",
    "CHANGE_PASS_FAILED_ERROR"          : "Nastala chyba. Skús to ešte raz",
    "OLD_PASSWORD"                      : "Současné heslo",

    // for vue-password component - strength of the password
    // @note: not used in this variant
    "PASS_VERY_WEAK"                    : "Velmi slabé",
    "PASS_WEAK"                         : "Slabé",
    "PASS_MEDIUM"                       : "Středně silné",
    "PASS_STRONG"                       : "Silné",
    "PASS_VERY_STRONG"                  : "Velmi silné",

    // page Onboarding (rules)
    "ONBOARDING_PAGE_TITLE"             : "Pravidlá",

    // page Focus (categories)
    "FOCUS_PAGE_TITLE"                  : "Zameranie",
    "FOCUS_CHOOSE_CATEGORY"             : "Vstúpiť",
    "FOCUS_CHOSEN_CATEGORY"             : "Zvolené!",
    "FOCUS_NONE_TITLE"                  : "Je mi to jedno",
    "FOCUS_HIGHLIGHTED_CATEGORY"        : "Dôležité!",
    "FOCUS_CHANGE_SUCCESS"              : "Zameranie zmenené!",
    "FOCUS_CHANGE_ERROR"                : "Nastala chyba. Skús to ešte raz",

    // page ItemDashboard
    "ITEM_DASHBOARD_PAGE_TITLE"         : "Úlohy",
    "ITEM_ANSWER_FAILED_ERROR"          : "Nastala chyba. Skús to ešte raz",
    "TO_ACHIEVEMENTS"                   : "Všetky úspechy",
    "EXPLANATION_HELP_TITLE"            : "Nevieš si pomôcť?",
    "EXPLANATION_HELP_TEXT"             : "Pokiaľ si nevieš poradiť s nejakou otázkou, odporúčame pozrieť sa do našej znalostnej databázy.",
    "EXPLANATION_HELP_LINK"             : "Link na nápovedu",
    "EXPLANATION_HELP_URL"              : "",
    "CATEGORY_INFO_SECTION_TITLE"       : "Práve riešiš úlohu zo zamerania",
    "CATEGORY_INFO_SECTION_BTN_MORE"    : "Viac info",
    "CATEGORY_INFO_SECTION_BTN_LESS"    : "Menej info",

    // page ItemDashboard - Item component
    "ITEM_CATEGORY"                     : "Zameranie úlohy",
    "TO_ONBOARDING"                     : "Pravidlá",
    "YOUR_FOCUS_IS"                     : "Tvoje primárne zameranie",
    "YOU_HAVE_NO_FOCUS"                 : "Nemáš zvolené žiadne primárne zameranie",
    "TO_FOCUS_CHANGE"                   : "Zmeniť",

    // page ItemDashboard - component Text Answer
    "YOUR_ANSWER"                       : "Tvoja odpoveď",

    // page ItemDashboard - component Choice Answer
    "SELECT_ALL_CORRECT_ANSWERS"        : "Označ všetky správne odpovede",
    "SELECT_ONE_CORRECT_ANSWER"         : "Označ jednu správnu odpoveď",
    "SELECT_2_TO_4_CORRECT_ANSWERS"     : "Označ {count} správne odpovede",
    "SELECT_5_AND_MORE_CORRECT_ANSWERS" : "Označ {count} správnych odpovedí",
    "INCORRECT_ANSWER_COUNT"            : "Nesprávny počet odpovedí.",
    "SELECT_SOME_ANSWERS"               : "Označ všetky správne odpovede",

    // page ItemDashboard - component AnswerButtonRow (answer/postpone)
    "ANSWER_BUTTON"                     : "Odpovedať",
    "ANOTHER_QUESTION_BUTTON"           : "Iná otázka",

    // page ItemDashboard -> Correct Answer, Incorrect Answer component
    "CORRECT_ANSWER_TITLE"              : "Správna odpoveď",
    "CORRECT_ANSWER_DESCRIPTION"        : "Správna odpoveď, je vidieť, kto sa vyzná!",
    "INCORRECT_ANSWER_TITLE"            : "Nesprávna odpoveď",
    "INCORRECT_ANSWER_DESCRIPTION"      : "Nevadí, nabudúce tomu prídeš na kĺb!",
    "POINTS_GAINED"                     : "{points} bodov",

    // page ItemDashboard - component No Items (clean table)
    "NO_ITEMS_TITLE"                    : "Máš čistý stôl",
    "NO_ITEMS_DESCRIPTION"              : "Máš čistý stôl, skvelá práca!",
    "NO_ITEMS_DESCRIPTION_LONG"         : "Splnil si už úplne všetky úlohy! Teraz si môžes zmerať sily s kolegami v <a href='#/battles'>Súbojoch</a> a získať tak ďalšie body.",
    "CHOOSE_FOCUS_FOR_TOMORROW"         : "Zmeniť zameranie",

    // page Achievements
    "ACHIEVEMENTS"                      : "Úspechy",
    "ACHIEVEMENTS_MAIN_TITLE"           : "Tvoje úspechy",
    "ACHIEVEMENTS_UNLOCKED_TITLE"       : "Úspechy, ktoré si dosiahol",
    "ACHIEVEMENTS_LOCKED_TITLE"         : "Úspechy, ktoré ťa čakajú",
    "NO_ACHIEVEMENTS_YET"               : "Zatiaľ nemáš žiadne úspechy!",
    "ALL_ACHIEVEMENTS_UNLOCKED"         : "Získal si už všetky úspechy! Si hviezda!",
    "CONTINUE_PLAYING"                  : "Pokračovať v hre",

    // page Leaderboard
    "LEADERBOARD"                       : "Rebríček",
    "LEADERBOARD_MAIN_TITLE"            : "Ako sa ti darí v porovnaní s ostatnými?",
    "LEADERBOARD_MAIN_SUBTITLE"         : "Posúď, aká je tvoja pozícia",
    "LEADERBOARD_YOUR_RANK"             : "Tvoja pozícia",
    "LEADERBOARD_EXPLANATION_TEXT"      : "Riešením úloh získavaš body, za ktoré postúpiš do vyššieho levelu. Všetci máte rovnaké šance!",
    "LEADERBOARD_OF_TEAM_PLAYERS"       : "Rebríček hráčov v tvojom tíme",
    "LEADERBOARD_OF_AREA_TEAMS"         : "Rebríček tímov",
    "LEADERBOARD_TEAMS_SCORE"           : "Body",
    "LEADERBOARD_TEAMS_SCORE_PER_USER"  : "Na hráča",
    "LEADERBOARD_OF_ALL_PLAYERS"        : "Tvoja pozícia v rebríčku všetkých hráčov",
    "LEADERBOARD_OF_BATTLES_IN_AREA"    : "Všetky súboje v tejto oblasti",
    "LEADERBOARD_OF_BATTLES_IN_TEAM"    : "Súboje v tvojom tíme",
    "LEADERBOARD_WINS"                  : "Výhry",
    "LEADERBOARD_TIES"                  : "Remízy",
    "LEADERBOARD_LOSSES"                : "Prehry",

    // User progress component
    "PROGRESS_DEFAULT_TITLE"            : "Ako sa ti doposiaľ darilo?",
    "PROGRESS_YOUR_LEVEL"               : "Tvoja úroveň",
    "PROGRESS_ANSWERED_ITEMS"           : "Vyriešené úlohy",
    "PROGRESS_YOUR_RANK"                : "Tvoja pozícia",
    "TO_LEADERBOARD"                    : "Rebríček",
    "PROGRESS_YOUR_ACHIEVEMENTS"        : "Úspechy",
    "PROGRESSBAR_TITLE"                 : "Postup v kariére",

    // page Error - displaying serious errors
    "ERROR_MAIN_TITLE"                  : "Nastala chyba",

    // page Not Found - redirect for non-existing pages
    "NOT_FOUND_MAIN_TITLE"              : "Stránka nenájdená",
    "NOT_FOUND_MAIN_TEXT"               : "Táto stránka neexistuje.",
    "NOT_FOUND_REDIRECT_BUTTON"         : "Chcem odtiaľto preč",

    // new level modal
    "MODAL_LEVEL_BUTTON"                : "Ok",
    "MODAL_LEVEL_UP_TITLE"              : "Vyšší level! Tak to je jazda.",
    "MODAL_LEVEL_UP_TEXT"               : "Pre teba neexistujú žiadne limity.",
    "MODAL_LEVEL_DOWN_TITLE"            : "Stratil si level!",
    "MODAL_LEVEL_DOWN_TEXT"             : "Čo sa dá robiť.",
    "MODAL_LEVEL_YOUR_NEW"              : "Tvoj nový level je",

    // new achievement modal
    "MODAL_ACHIEVEMENT_BUTTON"          : "Ok",
    "MODAL_ACHIEVEMENT_TITLE"           : "Získávaš trofej! Super!",
    "MODAL_ACHIEVEMENT_TITLE_PLURAL"    : "Získávaš trofeje! Super!",
    "MODAL_ACHIEVEMENT_TEXT"            : "Podarilo se ti dosiahnuť úspech! Len tak ďalej!",

    // page Battles (added 04/2018)
    "BATTLES_PAGE_TITLE"                : "Súboje",
    "BATTLES_ARENA"                     : "Aréna",
    "BATTLES_CONTINUE_BATTLE"           : "Pokračovať v súboji!",
    // Battles - component "No Battle" (before battle)
    "BATTLES_NO_BATTLE_TITLE_1"         : "Zatiaľ nie si v žiadnom súboji.",
    "BATTLES_NO_BATTLE_TITLE_2"         : "Nesvrbia ťa prsty?",
    "BATTLES_NO_BATTLE_TEXT"            : "Zvoľ si súpera.",
    "BATTLES_OPPONENT_SEARCH_PH"        : "Zadaj meno súpera",
    "BATTLES_OPPONENT_SEARCH_NOT_FOUND" : "Súper neexistuje, alebo je práve v inom súboji!",
    "BATTLES_OPPONENT_IS_BUSY"          : "Súper je momentálne v inom súboji, vyber iného súpera.",
    // Battles - component "Waiting for Opponent"
    "BATTLES_WAITING_TITLE"             : "Čakáme, či {variable} nezdúchne.",
    "BATTLES_WAITING_TEXT_1"            : "Tvoj protivník má 24 hodín na zdvihnutie hodenej rukavice.",
    "BATTLES_WAITING_TEXT_2"            : "Ak tvoju výzvu prijme, budeme ťa informovať e-mailom a súboj začne. Súboj potrvá",
    "BATTLES_WAITING_24H"               : "24 hodín",
    // Battles - component "Waiting for User"
    "BATTLES_CHALLENGED_TITLE"          : "ťa vyzýva na súboj!",
    "BATTLES_CHALLENGED_TEXT"           : "Potvrď svoj záujem, alebo to nechaj tak. Máš 24 hodín.",
    "BATTLES_CHALLENGED_POINTS"         : "Pamätaj, že odmietnutie znamená {variable} bodov.",
    "BATTLES_ACCEPT"                    : "Prijať",
    "BATTLES_REJECT"                    : "Odmietnuť",
    // Battles - component "Battle in Progress"
    "BATTLES_IN_PROGRESS_TITLE"         : "Prebieha súboj",
    "BATTLES_IN_PROGRESS_SUBTITLE"      : "Snaž sa to neprestreliť.",
    // Battles - component "Battle in Progress", component "Battle Progress"
    "BATTLES_PROGRESS_QUESTION_NO"      : "Otázka č. {variable}",
    "BATTLES_QUESTION_BOTH_CORRECT"     : "Remíza",
    "BATTLES_QUESTION_BOTH_INCORRECT"   : "To šlo vedľa",
    "BATTLES_QUESTION_WON"              : "Zásah!",
    "BATTLES_QUESTION_LOST"             : "Zranenie!",
    "BATTLES_QUESTION_WAIT"             : "Čakáme na súpera",
    // Battles - component "Battle in Progress", component "Battle Question"
    "BATTLES_QUESTION_SEND_ANSWER"      : "Odoslať riešenie",
    // Battles - user messages
    "BATTLE_CHALLENGE_ERROR"            : "Nastala chyba. Skús to ešte raz",
    "BATTLE_ACCEPT_SUCCESS"             : "Súboj zahájený!",
    "BATTLE_ACCEPT_ERROR"               : "Nastala chyba. Skús to ešte raz",
    "BATTLE_DECLINE_SUCCESS"            : "Súboj odmietnutý.",
    "BATTLE_DECLINE_ERROR"              : "Nastala chyba. Skús to ešte raz",
    "BATTLE_ANSWER_ERROR"               : "Nastala chyba. Skús to ešte raz",
    // Battles - modal Opponent Accepted
    "BATTLES_OPPONENT_ACCEPTED_TITLE"   : "prijíma tvoju výzvu na súboj!",
    "BATTLES_OPPONENT_ACCEPTED_TEXT"    : "Choď na stránku Súboje a môžeš začať s otázkami.",
    // Battles - modal Opponent Declined
    "BATTLES_OPPONENT_DECLINED_TITLE"   : "Tvoj protivník zdrhol.",
    "BATTLES_OPPONENT_DECLINED_TEXT"    : "Tu už z toho nič nebude. Radšej vyvolaj ďalší súboj, ináč tu vystojíš dieru.",
    "BATTLES_OK"                        : "OK",
    "BATTLES_VS"                        : "vs.",
    // Battles - modal Battle Won
    "BATTLES_WON_TITLE"                 : "Vyhral si!",
    "BATTLES_WON_TEXT"                  : "Tvoje meno sa teraz vyslovuje s rešpektem a možno ťa niekto pozve na pohárik.",
    // Battles - modal Battle Lost
    "BATTLES_LOST_TITLE"                : "Prehra!",
    "BATTLES_LOST_TEXT"                 : "Nevadí, nabudúce to určite dáš! A okrem toho, jazvy sú sexy. Sa hovorí.",
    // Battles - modal Battle Tie
    "BATTLES_TIE_TITLE"                 : "Remíza!",
    "BATTLES_TIE_TEXT"                  : "Otázkou zostáva, či ste obaja tak dobrí, alebo mizerní.",
    // Battles - User Progress component
    "PROGRESS_YOUR_BATTLES"             : "Súboje",

    // Knowledge Tests (added 06/2019)
    // @note: not used in this variant
    // Knowledge Test box - tile on "Focus" page
    "KNOWLEDGE_TEST_FOCUS_TITLE"        : "Znalostní test",
    "KNOWLEDGE_TEST_DEADLINE"           : "Deadline",
    "KNOWLEDGE_TEST_ATTEMPTS_0"         : "Nemáš už žádné pokusy.",
    "KNOWLEDGE_TEST_ATTEMPTS_1"         : "Máš ještě 1 pokus.",
    "KNOWLEDGE_TEST_ATTEMPTS_2_4"       : "Máš ještě {count} pokusy.",
    "KNOWLEDGE_TEST_ATTEMPTS_5_MORE"    : "Máš ještě {count} pokusů.",
    "KNOWLEDGE_TEST_NO_TEST"            : "V současnosti není otevřený žádný znalostní test.",
    "KNOWLEDGE_TEST_UNFINISHED_ATTEMPT" : "Máš neukončený pokus! Čas běží!",
    "KNOWLEDGE_TEST_YOUR_RESULT"        : "Tvoje skóre:",
    "KNOWLEDGE_TEST_PREVIOUS_SUCCESS"   : "Splněno",
    "KNOWLEDGE_TEST_PREVIOUS_FAIL"      : "Nesplněno",
    "KNOWLEDGE_TEST_PREVIOUS_MANUAL"    : "Vyhodnocuje se",
    "KNOWLEDGE_TEST_START_ATTEMPT"      : "Spusť test",
    "KNOWLEDGE_TEST_CONTINUE_ATTEMPT"   : "Pokračuj v testu",
    "KNOWLEDGE_TEST_LOADING"            : "Test se načítá...",
    // error messages shown when user clicks on "Start test"
    "KNOWLEDGE_TEST_INACTIVE"           : "Tento test je již po deadlinu.",
    "KNOWLEDGE_TEST_TIMEOUT"            : "Čas na tento pokus vypršel!",
    "KNOWLEDGE_TEST_NO_ATTEMPTS"        : "Nemáš už žádné pokusy.",
    "KNOWLEDGE_TEST_START_ERROR"        : "Nastala chyba. Skús to ešte raz",
    "KNOWLEDGE_TEST_ATTEMPT_ERROR"      : "Nastala chyba. Skús to ešte raz",
    // Main Test page - test video
    "KNOWLEDGE_TEST_VIDEO_TO_ITEMS"     : "Pokračovat na otázky",
    "KNOWLEDGE_TEST_VIDEO_TO_VIDEO"     : "Zpět na video",
    "KNOWLEDGE_TEST_ATT_DOWNLOAD"       : "Stáhnout prezentaci",
    // Main Test page - questions & user answers
    "KNOWLEDGE_TEST_MAIN_TITLE"         : "Znalostní test",
    "KNOWLEDGE_TEST_ATTEMPT"            : "Pokus",
    "KNOWLEDGE_TEST_TIME_REMAINING"     : "Čas do konce:",
    "KNOWLEDGE_TEST_NEXT_BTN"           : "Další otázka",
    "KNOWLEDGE_TEST_PREV_BTN"           : "Předchozí otázka",
    "KNOWLEDGE_TEST_QUESTION"           : "Otázka",
    "KNOWLEDGE_TEST_SUBMIT"             : "Vyhodnotit test",
    "KNOWLEDGE_TEST_SUBMIT_TEXT_PH"     : "Začni psát zde",
    "KNOWLEDGE_TEST_ANSWER"             : "Odpovědět",
    // Test results page
    "KNOWLEDGE_TEST_FINISH_ERROR"       : "Nastala chyba!",
    "KNOWLEDGE_TEST_SUCCESS"            : "Prošel jsi testem! Gratulujeme!",
    "KNOWLEDGE_TEST_FAIL"               : "Neprošel jsi testem, škoda!",
    "KNOWLEDGE_TEST_MANUAL_RESULT"      : "Výsledky testu",
    "KNOWLEDGE_TEST_CORRECT_ANSWERS"    : "Správné odpovědi:",
    "KNOWLEDGE_TEST_RESULT_POINTS"      : "Získané body:",
    "KNOWLEDGE_TEST_RESULT_CORRECT"     : "Správně",
    "KNOWLEDGE_TEST_RESULT_INCORRECT"   : "Nesprávně",
    "KNOWLEDGE_TEST_RESULT_NO_ANSWER"   : "Bez odpovědi",
    "KNOWLEDGE_TEST_RESULT_MANUAL"      : "Čeká na ruční vyhodnocení",
    "KNOWLEDGE_TEST_YOUR_ANSWER"        : "Tvoje odpověď",
    "KNOWLEDGE_TEST_CORRECT_ANSWER"     : "Správná odpověď",
    "KNOWLEDGE_TEST_POINTS"             : "+{variable} Bodů",
    "KNOWLEDGE_TEST_EXIT"               : "Ok",
    "KNOWLEDGE_TEST_NEW_ATTEMPT"        : "Nový pokus",
    "KNOWLEDGE_TEST_MANUAL_ITEM"        : "Túto úlohu ešte vyhodnocujeme.",
    "KNOWLEDGE_TEST_MANUAL_INFO"        : "Tento test ještě vyhodnocujeme, celkové hodnocení ti zašleme po jeho uzavření.",
    // modal shown when user clicks on "Start test"
    "KNOWLEDGE_TEST_MODAL_TITLE"        : "Chystáš se spustit znalostní test.",
    "KNOWLEDGE_TEST_MODAL_ENTER"        : "Rozumím, vstoupit",
    "KNOWLEDGE_TEST_MODAL_CANCEL"       : "Rozmyslel jsem si to",
    // Modal shown in "Test results page" before repeating the test
    "KNOWLEDGE_TEST_REPEAT_SUCCESS_TITLE" : "Chystáš se opakovat znalostní test, který jsi již úspěšně zvládl",
    "KNOWLEDGE_TEST_REPEAT_SUCCESS_TEXT"  : "Započteme ti skóre lepšího testu. Hodně štěstí!",
    "KNOWLEDGE_TEST_REPEAT_FAIL_TITLE"    : "Chystáš se opakovat znalostní test",
    "KNOWLEDGE_TEST_REPEAT_FAIL_TEXT"     : "Do hry se ti počítá skóre lepšího testu, ale abys test úspěšně absolvoval, musíš mít alespoň 80%. Hodně štěstí!",
    // modal shown when user leaves some questions unanswered and wants to finish the attempt
    "KNOWLEDGE_TEST_MODAL_FINISH_TITLE"   : "Chystáš se uzavřít test, ale neodpověděl jsi na všechny otázky.",
    "KNOWLEDGE_TEST_MODAL_FINISH_TEXT"    : "Nezodpovězené otázky se započítají jako nesprávné.",
    "KNOWLEDGE_TEST_MODAL_FINISH_CONFIRM" : "Vrátit se na test",
    "KNOWLEDGE_TEST_MODAL_FINISH_REJECT"  : "Rozumím, přesto uzavřít",
    // Modal shown when the time runs out
    "KNOWLEDGE_TEST_TIMEOUT_TITLE"       : "Čas vypršel!",
    "KNOWLEDGE_TEST_TIMEOUT_DESC"        : "Pokud máš alespoň 80% otázek zodpovězených správně, test jsi úspěšně absolvoval!",
    "KNOWLEDGE_TEST_TIMEOUT_BTN"         : "Zobrazit výsledky",

    // page Area Chooser (added 03/2023)
    "AREA_CHOOSER_PAGE_TITLE"           : "Oblasti",
    "AREA_CHOOSER_MAIN_TITLE"           : "Vyber si oblasť svojho rozvoja",
    "AREA_CHOOSER_MAIN_SUBTITLE_1"      : "Každá oblasť má unikátnu tému a kompetencie, ktoré si vďaka nej natrénuješ.",
    "AREA_CHOOSER_MAIN_SUBTITLE_2"      : "Spoločné majú jedno – pripravia ťa na budúcnosť.",
    "AREA_CHOOSER_CONTINUE"             : "Pokračovať",
    "AREA_CHOOSER_CURRENT_AREA"         : "Momentálne sa nachádzate v tejto oblasti",
    "AREA_CHOOSER_CHANGE_ERROR"         : "Nastala chyba. Skús to ešte raz",

    // validation messages
    "VALIDATION_required"               : "Pole {field} je povinné.",
    "VALIDATION_email"                  : "Pole {field} musí byť platnou emailovou adresou.",
    "VALIDATION_minLength"              : "Pole {field} musí byť dlhé aspoň {min} znakov.",
    "VALIDATION_answerLength"           : "Tvoja odpoveď musí mať presne {length} znakov",

    // names of form fields for validation purposes
    "VALIDATION_FIELDNAME_email"        : "Email",
    "VALIDATION_FIELDNAME_password"     : "Heslo",
    "VALIDATION_FIELDNAME_answer"       : "Odpoveď",
    "VALIDATION_FIELDNAME_name"         : "Meno",
    "VALIDATION_FIELDNAME_first_name"   : "Krstné meno",
    "VALIDATION_FIELDNAME_last_name"    : "Priezvisko",
    "VALIDATION_FIELDNAME_new_password" : "Nové heslo",
    "VALIDATION_FIELDNAME_old_password" : "Súčasné heslo",

    // Image alts
    "ALT_IMAGE_CATEGORY"                : "Obrázok zamerania",
    "ALT_IMAGE_BADGE"                   : "Obrázok trofeje",
    "ALT_IMAGE_ITEM"                    : "Ikona úlohy",
    "ALT_DESCRIPTION_IMAGE_ITEM"        : "Obrázok úlohy",
    "ALT_IMAGE_TEST_ITEM"               : "Obrázok otázky",
};

export default translationsSk;
