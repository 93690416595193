var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "panel panel-default category-info" }, [
    _c("div", { staticClass: "panel-body" }, [
      _c("div", { staticClass: "row category-info-title-row" }, [
        _c("div", { staticClass: "col-xs-3 col-sm-2 col-md-2" }, [
          _c("img", {
            staticClass: "img img-responsive",
            attrs: {
              src: _vm.currentItemCategory.image_path,
              alt: _vm._f("translate")("ALT_IMAGE_CATEGORY"),
            },
          }),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "col-xs-9",
            class: _vm.infoDetailIsNotEmpty
              ? "col-sm-7 col-md-8"
              : "col-sm-10 col-md-10",
          },
          [
            _c("h3", [
              _vm._v(
                _vm._s(_vm._f("translate")("CATEGORY_INFO_SECTION_TITLE"))
              ),
            ]),
            _vm._v(" "),
            _c("h2", [_vm._v(_vm._s(_vm.currentItemCategory.name))]),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.infoDetailIsNotEmpty,
                expression: "infoDetailIsNotEmpty",
              },
            ],
            staticClass: "col-xs-12 col-sm-3 col-md-2 category-info-btn-col",
          },
          [
            !_vm.infoDetailVisible
              ? _c(
                  "a",
                  {
                    on: {
                      click: function ($event) {
                        _vm.infoDetailVisible = true
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(
                          _vm._f("translate")("CATEGORY_INFO_SECTION_BTN_MORE")
                        ) +
                        "\n                    "
                    ),
                    _c("i", { staticClass: "fa fa-chevron-down" }),
                  ]
                )
              : _c("a", { on: { click: _vm.closeDetail } }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(
                        _vm._f("translate")("CATEGORY_INFO_SECTION_BTN_LESS")
                      ) +
                      "\n                    "
                  ),
                  _c("i", { staticClass: "fa fa-chevron-up" }),
                ]),
          ]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.infoDetailVisible && _vm.infoDetailIsNotEmpty,
              expression: "infoDetailVisible && infoDetailIsNotEmpty",
            },
          ],
          staticClass: "category-info-detail",
        },
        [
          _c(
            "h3",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.currentItemCategory.info_title,
                  expression: "currentItemCategory.info_title",
                },
              ],
            },
            [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.currentItemCategory.info_title) +
                  "\n            "
              ),
            ]
          ),
          _vm._v(" "),
          _vm.infoContentStripped
            ? _c("p", {
                staticClass: "info_content",
                domProps: {
                  innerHTML: _vm._s(_vm.currentItemCategory.info_content),
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.currentItemCategory.info_cta_url &&
          _vm.currentItemCategory.info_cta_title
            ? _c(
                "a",
                {
                  attrs: {
                    href: _vm.currentItemCategory.info_cta_url,
                    target: "_blank",
                  },
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.currentItemCategory.info_cta_title) +
                      "\n                "
                  ),
                  _c("i", { staticClass: "fa fa-external-link" }),
                ]
              )
            : _vm._e(),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }