<template>
    <navbar placement="static" type="default" :class="{ 'guest-navbar' : !isAuthenticated }">
        <router-link slot="brand" class="navbar-brand" to="/item-dashboard">
            <img v-if="isAuthenticated" src="~variantImages/logo_navbar.png"
                 class="logo-navbar"
            />
            <img v-else src="~variantImages/logo_navbar_guest.png"
                 class="logo-navbar"
            />
        </router-link>

        <!-- The navbar links - taken from config because of variants -->
        <router-link v-for="(route, langKey) in navbarItems" :key="langKey"
                     class="nav-item" 
                     tag="li" 
                     :to="route" exact 
                     v-if="isAuthenticated"
        >
            <a class="nav-link"> {{ langKey | translate }}</a>
        </router-link>

        <router-link class="nav-item user-info" tag="li" slot="right"
                     to="/profile" exact v-if="isAuthenticated"
                     data-toggle="collapse"
        >
            <div class="user-info-content-wrapper"
                 data-toggle="collapse" data-target=".navbar-toggle"
            >
                <li>
                    <a class="nav-link">{{ user.name }}</a>
                    <a class="user-points"
                         v-if="currentProgress"
                         @click="goToProfile()"
                    >{{ 'POINTS_COUNT' | translate }}: {{ currentProgress.score }}</a>
                </li>
            </div>
        </router-link>

        <li slot="right" class="logout" v-if="isAuthenticated">
            <a class="nav-link" @click="logout()">
                <i class="fa fa-power-off"></i> {{ 'LOGOUT' | translate }}
            </a>
        </li>

        <li slot="right" v-if="!isAuthenticated">
            <p class="navbar-btn">
                <button class="btn brand-primary-btn" @click="onLoginButton">
                    {{ 'LOGIN' | translate }}
                </button>
            </p>
        </li>

        <li slot="right" v-if="!isAuthenticated && !ssoEnabled">
            <a class="nav-link" @click="scrollToRegistration">
                {{ 'REGISTRATION' | translate }}
            </a>
        </li>
    </navbar>
</template>
<!---------------------------------------------------------------------------->
<script>
    import navbar from "vue-strap/src/Navbar.vue";
    import { mapGetters } from "vuex";

    import urlService from "src/services/urlService";

    import log from "src/services/logger";
    const logTag = "Navigation";

    export default {
        components : { navbar },
        computed : mapGetters([ "isAuthenticated", "user", "route", "currentProgress" ]),
        data() {
            return {
                ssoEnabled          : config.features.ssoLogin,
                navbarItems         : config.navbarItems
            };
        },
        methods : {
            logout() {
                log.log(logTag, "Logout clicked");
                this.$store.dispatch("sendLogoutRequest").then(() => {
                    this.$router.push("/login").catch(() => {});
                });
            },

            goToProfile() {
                this.$router.push("/profile");
            },

            onLoginButton() {
                if (this.ssoEnabled) {
                    log.log(logTag, "SSO login button pressed");

                    // if there are any params in the URL (e.g. area, category) we need to remember them
                    // before we redirect to SSO
                    urlService.storeUrlParams();

                    // We just redirect the user to SSO url, which will handle logging in and will return to
                    // this app by calling /token/:token router. App's router then handles the rest.
                    window.location.href = config.api.base + config.sso.url;
                } else {
                    this.scrollToLogin();
                }
            },

            // If the navbar's Login or Register links are pressed while user is on Login page, the Login's
            // mounted() will not be called and the navbar will change the component and scroll
            // If the user is not on Login page, navbar's links will redirect there and Login will change
            // the component and scroll in its mounted()
            scrollToLogin() {
                this.$store.dispatch("changeLoginComponent", "LoginForm");
                this.$scrollTo("#user-auth-area", 400, {});
                this.$router.push("/login/login");
            },

            scrollToRegistration() {
                this.$store.dispatch("changeLoginComponent", "RegistrationForm");
                this.$scrollTo("#user-auth-area", 400, {});
                this.$router.push("/login/registration");
            }
        }
    };
</script>
<!---------------------------------------------------------------------------->
<style lang="scss" type="text/scss">
</style>
