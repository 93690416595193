var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page", attrs: { id: "achievements" } }, [
    _c("div", { staticClass: "part-1" }, [
      _c("div", { staticClass: "container" }, [
        _c("h1", [
          _vm._v(_vm._s(_vm._f("translate")("ACHIEVEMENTS_MAIN_TITLE"))),
        ]),
        _vm._v(" "),
        _c("h3", [
          _vm._v(_vm._s(_vm.currentAreaDetails.achievements_main_subtitle)),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row achievements-title" }, [
          _c("div", { staticClass: "col-xs-12" }, [
            _c("h2", [
              _vm._v(
                _vm._s(_vm._f("translate")("ACHIEVEMENTS_UNLOCKED_TITLE"))
              ),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row achievements-row" }, [
          _vm.areThereAnyUnlocked
            ? _c(
                "ul",
                { staticClass: "list-group achievements-list" },
                _vm._l(_vm.currentUnlockedAchievements, function (achievement) {
                  return _c(
                    "div",
                    { key: achievement.id, staticClass: "col-xs-12 col-sm-6" },
                    [
                      _c("list-item", {
                        attrs: {
                          "img-path": achievement.image_path,
                          title: achievement.name,
                          desc: achievement.description,
                          tag: achievement.category_name,
                          "data-key": "unlockedAchievements",
                        },
                      }),
                    ],
                    1
                  )
                }),
                0
              )
            : _c("p", { staticClass: "empty-achievements" }, [
                _vm._v(_vm._s(_vm._f("translate")("NO_ACHIEVEMENTS_YET"))),
              ]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "part-2" }, [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "row achievements-title" }, [
          _c("div", { staticClass: "col-xs-12" }, [
            _c("h2", [
              _vm._v(_vm._s(_vm._f("translate")("ACHIEVEMENTS_LOCKED_TITLE"))),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row achievements-row" }, [
          _vm.areThereAnyLocked
            ? _c(
                "ul",
                { staticClass: "list-group achievements-list" },
                _vm._l(_vm.currentLockedAchievements, function (achievement) {
                  return _c(
                    "div",
                    { key: achievement.id, staticClass: "col-xs-12 col-sm-6" },
                    [
                      _c("list-item", {
                        attrs: {
                          "img-path": achievement.image_path,
                          title: achievement.name,
                          desc: achievement.description,
                          tag: achievement.category_name,
                          "data-key": "lockedAchievements",
                        },
                      }),
                    ],
                    1
                  )
                }),
                0
              )
            : _c("p", { staticClass: "empty-achievements" }, [
                _vm._v(
                  _vm._s(_vm._f("translate")("ALL_ACHIEVEMENTS_UNLOCKED"))
                ),
              ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "continue-btn-row" }, [
        _c("a", { staticClass: "btn primary-btn", attrs: { href: "#/" } }, [
          _vm._v(
            "\n                " +
              _vm._s(_vm._f("translate")("CONTINUE_PLAYING")) +
              "\n            "
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }