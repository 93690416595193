/**
 * This class holds all the messages that should be shown to user after some action
 */
import Vue from "vue";
import reduce from "lodash/reduce";

import apiDefinition from "src/config/apiDefinition";

const afterLogin = function(response) {
    let message = "";

    switch (response.status) {
        case apiDefinition.codes.ok :
        case apiDefinition.codes.created :
        case apiDefinition.codes.noContent :
        case apiDefinition.codes.partiallyOk :
            message = Vue.i18n.translate("LOGIN_SUCCESSFUL");
            break;
        case apiDefinition.codes.forbidden :
            message = Vue.i18n.translate("LOGIN_FAILED_CREDENTIALS");
            break;
        case apiDefinition.codes.internalServerError :
        default :
            message = Vue.i18n.translate("LOGIN_FAILED_ERROR");
            break;
    }

    return message;
};

const afterSsoLogin = function(response) {
    let message = "";

    switch (response.status) {
        case apiDefinition.codes.ok :
        case apiDefinition.codes.created :
        case apiDefinition.codes.noContent :
        case apiDefinition.codes.partiallyOk :
            message = Vue.i18n.translate("LOGIN_SUCCESSFUL");
            break;

        case apiDefinition.codes.notFound : // bad token, or token expired
            message = Vue.i18n.translate("LOGIN_FAILED_NOT_FOUND");
            break;
        case apiDefinition.codes.unprocessableEntity :  // no token sent
        case apiDefinition.codes.internalServerError :
        default :
            message = Vue.i18n.translate("LOGIN_FAILED_NOT_FOUND");
            break;
    }

    return message;
};

function afterRegistration(response) {
    let message = "";

    switch (response.status) {
        case apiDefinition.codes.ok :
        case apiDefinition.codes.created :
        case apiDefinition.codes.noContent :
        case apiDefinition.codes.partiallyOk :
            message = Vue.i18n.translate("REGISTRATION_SUCCESSFUL");
            break;
        case apiDefinition.codes.unprocessableEntity :
            message = Vue.i18n.translate("REGISTRATION_FAILED_VALIDATION");
            break;
        case apiDefinition.codes.internalServerError :
        default :
            message = Vue.i18n.translate("REGISTRATION_FAILED_ERROR");
            break;
    }

    return message;
}

function afterActivation(response) {
    let message = "";

    switch (response.status) {
        case apiDefinition.codes.ok :
            message = Vue.i18n.translate("ACTIVATION_SUCCESSFUL");
            break;
        case apiDefinition.codes.notFound :
            message = Vue.i18n.translate("ACTIVATION_FAILED_NOT_FOUND");
            break;
        case apiDefinition.codes.conflict :
            message = Vue.i18n.translate("ACTIVATION_FAILED_VERIFIED");
            break;
        case apiDefinition.codes.unprocessableEntity :
            message = Vue.i18n.translate("ACTIVATION_FAILED_VALIDATION");
            break;
        case apiDefinition.codes.internalServerError :
        default :
            message = Vue.i18n.translate("ACTIVATION_FAILED_ERROR");
            break;
    }

    return message;
}

function afterResetPassword(response) {
    let message = "";

    switch (response.status) {
        case apiDefinition.codes.ok :
            message = Vue.i18n.translate("RESET_PASS_SUCCESSFUL");
            break;
        case apiDefinition.codes.unprocessableEntity :
            // just show the messages from server
            message = reduce(response.data.errors, function(resultMessage, error) {
                return resultMessage + error + "\n ";
            }, "");
            message = message.trim();
            break;
        case apiDefinition.codes.internalServerError :
        default :
            message = Vue.i18n.translate("RESET_PASS_FAILED_ERROR");
            break;
    }

    return message;
}

function afterChangePassword(response) {
    let message = "";

    switch (response.status) {
        case apiDefinition.codes.ok :
            message = Vue.i18n.translate("CHANGE_PASS_SUCCESS");
            break;
        case apiDefinition.codes.unauthorized :
            message = Vue.i18n.translate("CHANGE_PASS_FAILED_UNAUTHORIZED");
            break;
        case apiDefinition.codes.unprocessableEntity :
            // just show the messages from server
            message = reduce(response.data.errors, function(resultMessage, error) {
                return resultMessage + error + "\n";
            }, "");
            message = message.trim();
            break;
        case apiDefinition.codes.internalServerError :
        default :
            message = Vue.i18n.translate("CHANGE_PASS_FAILED_ERROR");
            break;
    }

    return message;
}

function afterForgottenPass(response) {
    let message = "";

    switch (response.status) {
        case apiDefinition.codes.ok :
            message = Vue.i18n.translate("FORGOTTEN_PASS_SUCCESS");
            break;
        case apiDefinition.codes.unprocessableEntity :
            message = Vue.i18n.translate("FORGOTTEN_PASS_FAILED_VALIDATION");
            break;
        case apiDefinition.codes.internalServerError :
        default :
            message = Vue.i18n.translate("FORGOTTEN_PASS_FAILED_ERROR");
            break;
    }

    return message;
}

function afterAnswer(response) {
    let message = "";

    switch (response.status) {
        case apiDefinition.codes.internalServerError :
        default :
            message = Vue.i18n.translate("ITEM_ANSWER_FAILED_ERROR");
            break;
    }

    return message;
}

function afterFocusCategory(response) {
    let message = "";

    switch (response.status) {
        case apiDefinition.codes.ok :
        case apiDefinition.codes.created :
        case apiDefinition.codes.noContent :
        case apiDefinition.codes.partiallyOk :
            message = Vue.i18n.translate("FOCUS_CHANGE_SUCCESS");
            break;
        case apiDefinition.codes.internalServerError :
        default :
            message = Vue.i18n.translate("FOCUS_CHANGE_ERROR");
            break;
    }

    return message;
}

function getPasswordStrengthMessages() {
    return [
        Vue.i18n.translate("PASS_VERY_WEAK"),
        Vue.i18n.translate("PASS_WEAK"),
        Vue.i18n.translate("PASS_MEDIUM"),
        Vue.i18n.translate("PASS_STRONG"),
        Vue.i18n.translate("PASS_VERY_STRONG")
    ];
}

function afterBattleChallengeSent(response) {
    let message = "";

    switch (response.status) {
        case apiDefinition.codes.badRequest :
        case apiDefinition.codes.unprocessableEntity :
        case apiDefinition.codes.internalServerError :
        default :
            message = Vue.i18n.translate("BATTLE_CHALLENGE_ERROR");
            break;
    }

    return message;
}

// ###########################
// ### Battles
// ###########################
function afterBattleAccept(response) {
    let message = "";

    switch (response.status) {
        case apiDefinition.codes.ok :
        case apiDefinition.codes.created :
        case apiDefinition.codes.noContent :
        case apiDefinition.codes.partiallyOk :
            message = Vue.i18n.translate("BATTLE_ACCEPT_SUCCESS");
            break;
        case apiDefinition.codes.internalServerError :
        default :
            message = Vue.i18n.translate("BATTLE_ACCEPT_ERROR");
            break;
    }

    return message;
}

function afterBattleDecline(response) {
    let message = "";

    switch (response.status) {
        case apiDefinition.codes.ok :
        case apiDefinition.codes.created :
        case apiDefinition.codes.noContent :
        case apiDefinition.codes.partiallyOk :
            message = Vue.i18n.translate("BATTLE_DECLINE_SUCCESS");
            break;
        case apiDefinition.codes.internalServerError :
        default :
            message = Vue.i18n.translate("BATTLE_DECLINE_ERROR");
            break;
    }

    return message;
}

function afterBattleQuestionAnswer(response) {
    let message = "";

    switch (response.status) {
        case apiDefinition.codes.internalServerError :
        default :
            message = Vue.i18n.translate("BATTLE_ANSWER_ERROR");
            break;
    }

    return message;
}

// ###########################
// ### Knowledge Tests 6/2019
// ###########################
function afterStartTestAttempt(response) {
    let message = "";

    switch (response.status) {
        case apiDefinition.codes.ok :
            // do nothang
            break;
        case apiDefinition.codes.gone :
            message = Vue.i18n.translate("KNOWLEDGE_TEST_INACTIVE");
            break;
        case apiDefinition.codes.failedDependency :
            message = Vue.i18n.translate("KNOWLEDGE_TEST_TIMEOUT");
            break;
        case apiDefinition.codes.preconditionFailed :
            message = Vue.i18n.translate("KNOWLEDGE_TEST_NO_ATTEMPTS");
            break;
        case apiDefinition.codes.conflict :
            message = Vue.i18n.translate("KNOWLEDGE_TEST_UNFINISHED_ATTEMPT");
            break;
        case apiDefinition.codes.forbidden :
        case apiDefinition.codes.notFound :
        case apiDefinition.codes.internalServerError :
        default :
            message = Vue.i18n.translate("KNOWLEDGE_TEST_START_ERROR");
            break;
    }

    return message;
}

function afterDownloadTestAttempt(response) {
    let message = "";

    switch (response.status) {
        case apiDefinition.codes.ok :
            // do nothang
            break;
        case apiDefinition.codes.forbidden :
        case apiDefinition.codes.notFound :
        case apiDefinition.codes.internalServerError :
        default :
            message = Vue.i18n.translate("KNOWLEDGE_TEST_ATTEMPT_ERROR");
            break;
    }

    return message;
}

function afterTestAnswerAndFinish(response) {
    let message = "";

    switch (response.status) {
        case apiDefinition.codes.ok :
            // do nothang
            break;
        case apiDefinition.codes.gone :
            message = Vue.i18n.translate("KNOWLEDGE_TEST_INACTIVE");
            break;
        case apiDefinition.codes.failedDependency :
            message = Vue.i18n.translate("KNOWLEDGE_TEST_TIMEOUT");
            break;
        case apiDefinition.codes.forbidden :
        case apiDefinition.codes.notFound :
        case apiDefinition.codes.unprocessableEntity :
        case apiDefinition.codes.internalServerError :
        default :
            message = Vue.i18n.translate("KNOWLEDGE_TEST_ATTEMPT_ERROR");
            break;
    }

    return message;
}

function afterSetVideoWatched(response) {
    let message = "";

    switch (response.status) {
        case apiDefinition.codes.ok :
            // do nothang
            break;
        case apiDefinition.codes.gone :
            message = Vue.i18n.translate("KNOWLEDGE_TEST_INACTIVE");
            break;
        case apiDefinition.codes.failedDependency :
            message = Vue.i18n.translate("KNOWLEDGE_TEST_TIMEOUT");
            break;
        case apiDefinition.codes.forbidden :
        case apiDefinition.codes.notFound :
        case apiDefinition.codes.unprocessableEntity :
        case apiDefinition.codes.internalServerError :
        default :
            message = Vue.i18n.translate("KNOWLEDGE_TEST_ATTEMPT_ERROR");
            break;
    }

    return message;
}

// ###########################
// ### Area Chooser 03/2023
// ###########################
function afterChangePreferredArea(response) {
    let message = "";

    // @note: no idea what errors can server return, so just show the error
    switch (response.status) {
        default :
            message = Vue.i18n.translate("AREA_CHOOSER_CHANGE_ERROR");
            break;
    }

    return message;
};



export default {
    afterLogin,
    afterSsoLogin,
    afterRegistration,
    afterActivation,
    afterResetPassword,
    afterChangePassword,
    afterForgottenPass,
    afterAnswer,
    afterFocusCategory,
    getPasswordStrengthMessages,

    // Battles
    afterBattleChallengeSent,
    afterBattleAccept,
    afterBattleDecline,
    afterBattleQuestionAnswer,

    // Knowledge Tests
    afterStartTestAttempt,
    afterDownloadTestAttempt,
    afterTestAnswerAndFinish,
    afterSetVideoWatched,

    // Area Chooser
    afterChangePreferredArea
};
