var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "item-view no-items-view" }, [
    _c("h3", [_vm._v(_vm._s(_vm._f("translate")("NO_ITEMS_TITLE")))]),
    _vm._v(" "),
    _c("div", { staticClass: "bg-img img-responsive" }),
    _vm._v(" "),
    _c("p", { staticClass: "no-items-description" }, [
      _vm._v(_vm._s(_vm._f("translate")("NO_ITEMS_DESCRIPTION"))),
    ]),
    _vm._v(" "),
    _c("p", {
      staticClass: "no-items-description",
      domProps: {
        innerHTML: _vm._s(
          _vm.$options.filters.translate("NO_ITEMS_DESCRIPTION_LONG")
        ),
      },
    }),
    _vm._v(" "),
    _vm.showCategoryInfo
      ? _c("p", [
          _vm.isAnyCategoryFocused
            ? _c("span", [
                _c("span", { staticClass: "focus-title" }, [
                  _vm._v(_vm._s(_vm._f("translate")("YOUR_FOCUS_IS")) + ":"),
                ]),
                _vm._v(
                  " " + _vm._s(_vm.currentFocusedCategory.name) + ".\n        "
                ),
              ])
            : _c("span", { staticClass: "focus-title" }, [
                _vm._v(_vm._s(_vm._f("translate")("YOU_HAVE_NO_FOCUS")) + "."),
              ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    !_vm.isAnyCategoryFocused || _vm.currentCategories.length > 1
      ? _c(
          "a",
          { staticClass: "btn secondary-btn", attrs: { href: "#/focus" } },
          [
            _vm._v(
              "\n        " +
                _vm._s(_vm._f("translate")("CHOOSE_FOCUS_FOR_TOMORROW")) +
                "\n    "
            ),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }