var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "navbar",
    {
      class: { "guest-navbar": !_vm.isAuthenticated },
      attrs: { placement: "static", type: "default" },
    },
    [
      _c(
        "router-link",
        {
          staticClass: "navbar-brand",
          attrs: { slot: "brand", to: "/item-dashboard" },
          slot: "brand",
        },
        [
          _vm.isAuthenticated
            ? _c("img", {
                staticClass: "logo-navbar",
                attrs: { src: require("variantImages/logo_navbar.png") },
              })
            : _c("img", {
                staticClass: "logo-navbar",
                attrs: { src: require("variantImages/logo_navbar_guest.png") },
              }),
        ]
      ),
      _vm._v(" "),
      _vm._l(_vm.navbarItems, function (route, langKey) {
        return _vm.isAuthenticated
          ? _c(
              "router-link",
              {
                key: langKey,
                staticClass: "nav-item",
                attrs: { tag: "li", to: route, exact: "" },
              },
              [
                _c("a", { staticClass: "nav-link" }, [
                  _vm._v(" " + _vm._s(_vm._f("translate")(langKey))),
                ]),
              ]
            )
          : _vm._e()
      }),
      _vm._v(" "),
      _vm.isAuthenticated
        ? _c(
            "router-link",
            {
              staticClass: "nav-item user-info",
              attrs: {
                slot: "right",
                tag: "li",
                to: "/profile",
                exact: "",
                "data-toggle": "collapse",
              },
              slot: "right",
            },
            [
              _c(
                "div",
                {
                  staticClass: "user-info-content-wrapper",
                  attrs: {
                    "data-toggle": "collapse",
                    "data-target": ".navbar-toggle",
                  },
                },
                [
                  _c("li", [
                    _c("a", { staticClass: "nav-link" }, [
                      _vm._v(_vm._s(_vm.user.name)),
                    ]),
                    _vm._v(" "),
                    _vm.currentProgress
                      ? _c(
                          "a",
                          {
                            staticClass: "user-points",
                            on: {
                              click: function ($event) {
                                return _vm.goToProfile()
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm._f("translate")("POINTS_COUNT")) +
                                ": " +
                                _vm._s(_vm.currentProgress.score)
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]),
                ]
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isAuthenticated
        ? _c(
            "li",
            { staticClass: "logout", attrs: { slot: "right" }, slot: "right" },
            [
              _c(
                "a",
                {
                  staticClass: "nav-link",
                  on: {
                    click: function ($event) {
                      return _vm.logout()
                    },
                  },
                },
                [
                  _c("i", { staticClass: "fa fa-power-off" }),
                  _vm._v(
                    " " + _vm._s(_vm._f("translate")("LOGOUT")) + "\n        "
                  ),
                ]
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.isAuthenticated
        ? _c("li", { attrs: { slot: "right" }, slot: "right" }, [
            _c("p", { staticClass: "navbar-btn" }, [
              _c(
                "button",
                {
                  staticClass: "btn brand-primary-btn",
                  on: { click: _vm.onLoginButton },
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm._f("translate")("LOGIN")) +
                      "\n            "
                  ),
                ]
              ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.isAuthenticated && !_vm.ssoEnabled
        ? _c("li", { attrs: { slot: "right" }, slot: "right" }, [
            _c(
              "a",
              {
                staticClass: "nav-link",
                on: { click: _vm.scrollToRegistration },
              },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm._f("translate")("REGISTRATION")) +
                    "\n        "
                ),
              ]
            ),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }