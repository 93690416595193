<template>
    <div id="error-page" class="page">
        <div class="part-1">
            <div class="container">
                <h1>{{ 'ERROR_MAIN_TITLE' | translate }}!</h1>

                <div class="row">
                    <div class="col-xs-12 col-sm-6 col-sm-offset-3 col-md-4 col-md-offset-4">
                        <div class="alert alert-danger text-center" role="alert"
                             v-if="errorPageContent"
                        >{{ errorPageContent }}</div>
                    </div>
                </div>

                <a class="btn brand-secondary-btn" href="#/">
                    {{ 'NOT_FOUND_REDIRECT_BUTTON' | translate }}
                </a>
            </div>
        </div>
    </div>
</template>
<!---------------------------------------------------------------------------->
<script>
    import { mapGetters } from "vuex";

    export default {
        computed : mapGetters([ "errorPageContent" ]),
    };
</script>
<!---------------------------------------------------------------------------->
<style lang="scss" type="text/scss">
</style>