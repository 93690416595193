import VueRouter from "vue-router";

import Login from "Components/Login";
import ResetPassword from "Components/ResetPassword";
import Profile from "Components/Profile";
import AreaChooser from "Components/AreaChooser";
import Achievements from "Components/Achievements";
import Focus from "Components/Focus";
import ItemDashboard from "Components/ItemDashboard";
import Leaderboard from "Components/Leaderboard";
import Onboarding from "Components/Onboarding";
import Battles from "Components/Battles";
import KnowledgeTest from "Components/KnowledgeTest";
import ErrorPage from "Components/ErrorPage";
import NotFound from "Components/NotFound";

import store from "src/store/store";
import authenticationService from "src/services/api/authenticationService";
import userMessages from "src/services/userMessages";
import urlService from "src/services/urlService";

const routes = [
    {
        path : "/login/:component?",
        component : Login,
        meta : { requiresAuth : false }
    },

    {
        path : "/onboarding",
        component : Onboarding,
        meta : { requiresAuth : true },
    },
    {
        path : "/focus",
        component : Focus,
        meta : { requiresAuth : true }
    },
    {
        path : "/",
        component : ItemDashboard,
        meta : { requiresAuth : true },
    },
    {
        path : "/item-dashboard",
        redirect : "/",
        meta : { requiresAuth : true }
    },
    {
        path : "/areas",
        component : AreaChooser,
        meta : { requiresAuth : true },
    },
    {
        path : "/profile",
        component : Profile,
        meta : { requiresAuth : true },
    },
    {
        path : "/achievements",
        component : Achievements,
        meta : { requiresAuth : true },
    },

    {
        path : "/leaderboard",
        component : Leaderboard,
        meta : { requiresAuth : true },
    },

    {
        path : "/battles",
        component : Battles,
        meta : { requiresAuth : true },
    },

    {
        path : "/test",
        component : KnowledgeTest,
        meta : { requiresAuth : true },
    },

    {
        path : "/token/:token",
        meta : { requiresAuth : false },
        beforeEnter : (to, from, next) => {
            
            // if we stored any URL search params to localStorage before redirecting to SSO, restore them now
            urlService.restoreUrlParams();

            if (!to.params.token) {
                next("/login");
                store.dispatch("showErrorMessage", userMessages.afterSsoLogin({ status : 500 }));
                return;
            }

            store.dispatch("sendSsoLoginRequest", { token : to.params.token })
            .then((response) => {

                // if we stored an auth redirect path before being redirected to /login, redirect there now
                const authRedirectPath = JSON.parse(localStorage.getItem("authRedirectPath")) || null;

                if (authRedirectPath) {
                    console.log(`[router] We have authRedirectPath in localStorage, redirecting to: ${authRedirectPath}`);
                    localStorage.removeItem("authRedirectPath");
                    next(authRedirectPath);

                // if we use AreasChooser feature, let's redirect directly to areas chooser
                } else if (config.features.areaChooserPage) {
                    next("/areas");

                // otherwise, go to either onboarding, or focus - depends on whether the user was onboarded
                // before
                } else if (!response.data.email
                    || authenticationService.checkIfUserWasOnboardedAndStore(response.data.email))
                {
                    next("/focus");
                } else {
                    next("/onboarding");
                }

                store.dispatch("showSuccessMessage", userMessages.afterSsoLogin(response));
            })

            // login unsuccessful, show the error, clear the password field
            .catch(reason => {
                console.log(reason);
                next("/login");
                store.dispatch("showErrorMessage", userMessages.afterSsoLogin(reason));
            });
        }
    },

    // this is the route for email verification - the user gets the link from activation email. This route
    // will send the email and token to server and if the activation is successful, the user is logged in
    {
        path : "/email-verification/:email/:token",
        meta : { requiresAuth : false },
        beforeEnter : (to, from, next) => {
            store.dispatch("sendActivateRequest", { email : to.params.email, token : to.params.token })
            .then((response) => {
                store.dispatch("showSuccessMessage", userMessages.afterActivation(response));
                // let's forget about the auth redirect path (was not a requested feature)
                localStorage.removeItem("authRedirectPath");
                // this is user's first login, so redirect to onboarding
                next("/onboarding");
            })
            .catch((reason) => {
                // redirect to separate error page where user can read the error message etc
                store.dispatch("setErrorPageContent", userMessages.afterActivation(reason));
                next("/error");
            });
        }
    },
    // a separate error page where user can read an error message - e.g. after failed email verification
    {
        path : "/error",
        component : ErrorPage,
        meta : { requiresAuth : false }
    },
    {
        path : "/reset-password/:email/:token",
        component : ResetPassword,
        meta : { requiresAuth : false },
    },
    {
        path : "*",
        component : NotFound
    },
];

const router = new VueRouter({
    routes,
    // mode : "history",
    // base : __dirname,
    linkActiveClass : "active"
});

router.beforeEach((to, from, next) => {
    // scroll to top
    window.scrollTo(0, 0);

    // redirect if the route needs authentication and the user is not logged in
    if (to.matched.some(record => record.meta.requiresAuth)) {
        // console.log("isAuthenticated: " + store.getters.isAuthenticated);
        if (!store.getters.isAuthenticated) {
            // store the path they were in so we can redirect back after login
            localStorage.setItem("authRedirectPath", JSON.stringify(to.fullPath));
            next({ path : "/login" });
        } else {
            next();
        }
    } else {
        next();
    }
});

export default router;
