var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page", attrs: { id: "item-dashboard" } }, [
    _c("div", { staticClass: "part-1" }, [
      _c(
        "div",
        { staticClass: "container" },
        [
          _vm.showUserProgressOnTop
            ? _c("user-progress", {
                attrs: {
                  stats: _vm.currentProgress,
                  showProgressbar: "",
                  progressbarValue: _vm.currentAreaLevelProgress,
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.isThereAnyItem && _vm.showCategoryInfoSection
            ? _c("category-info")
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "row item-view-row" }, [
            _c(
              "div",
              {
                staticClass:
                  "col-sm-12 col-md-8 col-md-push-4 col-lg-7 col-lg-push-5",
              },
              [
                _c(
                  "div",
                  { staticClass: "panel panel-default item-view-wrapper" },
                  [
                    _c(
                      "div",
                      { staticClass: "panel-body" },
                      [
                        _c(
                          "transition",
                          { attrs: { name: "component-fade", mode: "out-in" } },
                          [
                            _c(_vm.currentItemComponent, {
                              tag: "component",
                              on: { next: _vm.switchItemComponent },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "col-sm-12 col-md-4 col-md-pull-8 col-lg-5 col-lg-pull-7 explanation-col",
              },
              [
                _c("h2", [
                  _vm._v(
                    _vm._s(
                      _vm.currentAreaDetails.items_explanation_section_title
                    )
                  ),
                ]),
                _vm._v(" "),
                _c("h3", [
                  _vm._v(
                    _vm._s(_vm.currentAreaDetails.items_explanation_first_title)
                  ),
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    _vm._s(_vm.currentAreaDetails.items_explanation_first_text)
                  ),
                ]),
                _vm._v(" "),
                _c("h3", [
                  _vm._v(
                    _vm._s(
                      _vm.currentAreaDetails.items_explanation_second_title
                    )
                  ),
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    _vm._s(_vm.currentAreaDetails.items_explanation_second_text)
                  ),
                ]),
                _vm._v(" "),
                _vm.showHelpSection
                  ? [
                      _c("h3", [
                        _vm._v(
                          _vm._s(_vm._f("translate")("EXPLANATION_HELP_TITLE"))
                        ),
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(
                          _vm._s(_vm._f("translate")("EXPLANATION_HELP_TEXT")) +
                            "\n                            "
                        ),
                        _c(
                          "a",
                          {
                            attrs: {
                              href: _vm._f("translate")("EXPLANATION_HELP_URL"),
                              target: "_blank",
                            },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm._f("translate")("EXPLANATION_HELP_LINK")
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                      ]),
                    ]
                  : _vm._e(),
              ],
              2
            ),
          ]),
          _vm._v(" "),
          !_vm.showUserProgressOnTop
            ? _c("user-progress", {
                attrs: {
                  stats: _vm.currentProgress,
                  showProgressbar: "",
                  progressbarValue: _vm.currentAreaLevelProgress,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "part-2" }, [
      _c(
        "div",
        { staticClass: "container" },
        [
          _c("h2", [
            _vm._v(_vm._s(_vm._f("translate")("ACHIEVEMENTS_MAIN_TITLE"))),
          ]),
          _vm._v(" "),
          _c("latest-achievements", {
            attrs: { achievements: _vm.currentUnlockedAchievements },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "btn-row" }, [
            _c(
              "a",
              {
                staticClass: "btn secondary-btn",
                attrs: { href: "#/achievements" },
              },
              [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm._f("translate")("TO_ACHIEVEMENTS")) +
                    "\n                "
                ),
              ]
            ),
          ]),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }