var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page", attrs: { id: "not-found" } }, [
    _c("div", { staticClass: "part-1" }, [
      _c("div", { staticClass: "container" }, [
        _c("h1", [_vm._v(_vm._s(_vm._f("translate")("NOT_FOUND_MAIN_TITLE")))]),
        _vm._v(" "),
        _c("p", { staticClass: "text-center" }, [
          _vm._v(
            "\n                " +
              _vm._s(_vm._f("translate")("NOT_FOUND_MAIN_TEXT")) +
              "\n            "
          ),
        ]),
        _vm._v(" "),
        _c(
          "a",
          { staticClass: "btn brand-secondary-btn", attrs: { href: "#/" } },
          [
            _vm._v(
              "\n                " +
                _vm._s(_vm._f("translate")("NOT_FOUND_REDIRECT_BUTTON")) +
                "\n            "
            ),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }