<template>
    <div id="item-dashboard" class="page">
        <div class="part-1">
            <div class="container">
                <user-progress :stats="currentProgress"
                               showProgressbar
                               :progressbarValue="currentAreaLevelProgress"
                               v-if="showUserProgressOnTop"
                ></user-progress>

                <category-info v-if="isThereAnyItem && showCategoryInfoSection"></category-info>

                <div class="row item-view-row">
                    <div class="col-sm-12 col-md-8 col-md-push-4 col-lg-7 col-lg-push-5">
                        <div class="panel panel-default item-view-wrapper">
                            <div class="panel-body">
                                <transition name="component-fade" mode="out-in">
                                    <!-- This component will show either component with "current item", or "clean table",
                                         or "your answer is correct" or "your answer is incorrect" -->
                                    <component :is="currentItemComponent" @next="switchItemComponent"></component>
                                </transition>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-12 col-md-4 col-md-pull-8 col-lg-5 col-lg-pull-7 explanation-col">
                        <h2>{{ currentAreaDetails.items_explanation_section_title }}</h2>

                        <h3>{{ currentAreaDetails.items_explanation_first_title }}</h3>
                        <p>{{ currentAreaDetails.items_explanation_first_text }}</p>

                        <h3>{{ currentAreaDetails.items_explanation_second_title }}</h3>
                        <p>{{ currentAreaDetails.items_explanation_second_text }}</p>

                        <template v-if="showHelpSection">
                            <h3>{{ 'EXPLANATION_HELP_TITLE' | translate }}</h3>
                            <p>{{ 'EXPLANATION_HELP_TEXT' | translate }}
                                <a :href="'EXPLANATION_HELP_URL' | translate" target="_blank">
                                    {{ 'EXPLANATION_HELP_LINK' | translate }}
                                </a>
                            </p>
                        </template>
                    </div>
                </div>

                <user-progress :stats="currentProgress"
                               showProgressbar
                               :progressbarValue="currentAreaLevelProgress"
                               v-if="!showUserProgressOnTop"
                ></user-progress>
            </div>
        </div>

        <div class="part-2">
            <div class="container">
                <h2>{{ 'ACHIEVEMENTS_MAIN_TITLE' | translate }}</h2>

                <latest-achievements :achievements="currentUnlockedAchievements"
                ></latest-achievements>

                <div class="btn-row">
                    <a href="#/achievements" class="btn secondary-btn">
                        {{ 'TO_ACHIEVEMENTS' | translate }}
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>
<!---------------------------------------------------------------------------->
<script>
    import { mapGetters } from "vuex";

    import UserProgress from "Components/common/UserProgress";
    import CategoryInfo from "./CategoryInfo";
    import Item from "./Item";
    import NoItems from "./NoItems";
    import CorrectAnswer from "./CorrectAnswer";
    import IncorrectAnswer from "./IncorrectAnswer";
    import LatestAchievements from "./LatestAchievements";

    export default {
        components : {
            UserProgress, CategoryInfo, Item, NoItems, CorrectAnswer, IncorrectAnswer, LatestAchievements
        },

        created : function() {
            this.$store.dispatch("downloadAreas").catch(() => {});
            this.$store.dispatch('downloadAchievements').catch(() => {}); // @todo: here or in init or both?
            this.drawItem();
        },

        data() {
            return {
                currentItemComponent        : "",
                showHelpSection             : config.features.showHelpSectionInItemDashboard,
                showCategoryInfoSection     : config.features.showCategoryInfoSectionInItemDashboard,
                showUserProgressOnTop       : config.features.showUserProgressOnTop,
            };
        },

        computed : mapGetters([
            "isThereAnyItem", "currentProgress", "currentUnlockedAchievements", "areThereAnyUnlocked",
            "currentAreaLevelProgress", "currentAreaDetails",
        ]),

        methods : {
            drawItem() {
                this.$store.dispatch("drawItem").then(() => {
                    this.currentItemComponent = this.isThereAnyItem ? "Item" : "NoItems";
                });
            },

            switchItemComponent({ action, correct }) {
                switch (action) {
                    case "answer" :
                        this.currentItemComponent = correct ? "CorrectAnswer" : "IncorrectAnswer";
                        break;
                    // in case of error - currently only 404 - automatically redraw
                    case "nextItem" :
                    case "postpone" :
                    case "error" :
                    default:
                        this.drawItem();
                        break;
                }
            }
        }
    };
</script>
<!---------------------------------------------------------------------------->
<style lang="scss" type="text/scss">
</style>
