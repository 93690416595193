<template>
    <footer>
        <div class="container">
            <div class="row">

                <template v-if="variant === 'ihrdinove'">
                    <img src="~variantImages/logo_footer.png" class="img" />

                    <div class="col-xs-12 col-sm-4 left-column">
                        <a href="https://www.innogy.cz/">innogy</a>
                        &copy;2017 {{ 'CREATED_BY' | translate }}
                        <a href="http://www.luducrafts.com/">Luducrafts</a>,
                        v{{version}}
                    </div>

                    <div class="col-xs-12 col-sm-4 text-center middle-column">
                        {{ 'FOR_MORE_INFO_CONTACT' | translate }}
                        <a href="mailto:hrej@innogy.cz">hrej@innogy.cz</a>
                    </div>

                    <div class="col-xs-12 col-sm-4 right-column">
                        {{ 'FOOTER_RIGHT_TEXT' | translate }}
                    </div>
                </template>
                <template v-if="variant === 'akademia'">
                    <div class="col-xs-12 col-sm-3 col-md-2 col-lg-3 left-column">
                        <img src="~variantImages/logo_footer.svg" class="img" />
                    </div>

                    <div class="col-xs-12 col-sm-6 col-md-8 col-lg-6 text-center middle-column">
                        Akadémia Budúcnosti
                        |
                        {{ 'CREATED_BY' | translate }} <a href="http://www.luducrafts.com/">Luducrafts</a>
                        |
                        &copy;2024 Slovenská sporiteľňa, a.s.
                    </div>

                    <div class="col-xs-12 col-sm-3 col-md-2 col-lg-3 right-column">
                        {{ 'FOOTER_RIGHT_TEXT' | translate }}
                    </div>
                </template>
            </div>
        </div>
    </footer>
</template>
<!---------------------------------------------------------------------------->
<script>
    export default {
        data() {
            return {
                version : PACKAGE_VERSION,
                variant : VARIANT
            };
        }
    };
</script>
<!---------------------------------------------------------------------------->
<style lang="scss" type="text/scss">
</style>
