<template>
    <div class="user-progress">

        <div class="row metric-row">
            <div class="col-xs-12 col-sm-6 col-md-3">
                <div class="row vertical-align">
                    <div class="metric progress-level">
                        <div class="col-xs-4 img-col">
                            <div class="bg-img img-responsive"
                                 v-if="stats.level"
                                 :style="{ backgroundImage: 'url(\'' + stats.level.image_path + '\')' }"
                            ></div>
                        </div>
                        <div class="col-xs-8">
                            <div class="row metric-title">{{ 'PROGRESS_YOUR_LEVEL' | translate }}</div>
                            <div class="row metric-value" v-if="stats.level">{{ stats.level.name }}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-xs-12 col-sm-6 col-md-3">
                <div class="row vertical-align">
                    <div class="metric progress-items">
                        <div class="col-xs-4 img-col">
                            <div class="bg-img img-responsive"></div>
                        </div>
                        <div class="col-xs-8">
                            <div class="row metric-title">{{ 'PROGRESS_ANSWERED_ITEMS' | translate }}</div>
                            <div class="row metric-value">{{ stats.answered_items_count }}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-xs-12 col-sm-6 col-md-3">
                <div class="row vertical-align">
                    <div class="metric progress-rank">
                        <div class="col-xs-4 img-col">
                            <div class="bg-img img-responsive"></div>
                        </div>
                        <div class="col-xs-8">
                            <div class="row metric-title">{{ 'PROGRESS_YOUR_RANK' | translate }}</div>
                            <div class="row metric-value">#{{ stats.rank }}
                                <span v-if="showLeaderboardLink" class="leaderboard-link-wrapper">
                                    (<router-link class="leaderboard-link" tag="a" to="/leaderboard">{{ 'TO_LEADERBOARD' | translate }}</router-link>)
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Disabled 04/2018 to make room for Battles -->
            <!--<div class="col-xs-12 col-sm-6 col-md-3">-->
                <!--<div class="row vertical-align">-->
                    <!--<div class="metric progress-achievements">-->
                        <!--<div class="col-xs-4 img-col">-->
                            <!--<div class="bg-img img-responsive"></div>-->
                        <!--</div>-->
                        <!--<div class="col-xs-8">-->
                            <!--<div class="row metric-title">{{ 'PROGRESS_YOUR_ACHIEVEMENTS' | translate }}:</div>-->
                            <!--<div class="row metric-value" v-if="stats.achievements">-->
                                <!--{{ stats.achievements.achieved_count }} / {{ stats.achievements.total }}-->
                            <!--</div>-->
                        <!--</div>-->
                    <!--</div>-->
                <!--</div>-->
            <!--</div>-->

            <div class="col-xs-12 col-sm-6 col-md-3">
                <div class="row vertical-align">
                    <div class="metric progress-battles">
                        <div class="col-xs-4 img-col">
                            <div class="bg-img img-responsive"></div>
                        </div>
                        <div class="col-xs-8">
                            <div class="row metric-title">{{ 'PROGRESS_YOUR_BATTLES' | translate }}</div>
                            <div class="row metric-value" v-if="stats.battles">
                                <span class="battles-won">{{ stats.battles.won }}</span>
                                / <span class="battles-lost">{{ stats.battles.lost }}</span>
                                / <span class="battles-tie">{{ stats.battles.tie }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="showProgressbar" class="progressbar-section">
            <p class="progressbar-title">{{ 'PROGRESSBAR_TITLE' | translate }}</p>

            <div class="row level-names">
                <div class="col-xs-6 level-current" v-if="stats.level">
                    {{ stats.level.name }}
                </div>
                <div class="col-xs-6 level-next" v-if="stats.level">
                    {{ stats.level.next_name }}
                </div>
            </div>

            <div class="progress"
                 :class="{'progress-full' : progressbarValue >= 100 }"
            >
                <progressbar :now="progressbarValue"
                             type="success"
                             striped
                ></progressbar>
            </div>
        </div>

    </div>
</template>
<!---------------------------------------------------------------------------->
<script>
    import progressbar from 'vue-strap/src/Progressbar.vue';

    export default {
        components : { progressbar },

        props : {
            stats : { type : Object, default : function() { return {}; } },
            showProgressbar : { type : Boolean, default : false },
            showLeaderboardLink : { type : Boolean, default : true },
            progressbarValue : { type : Number, default : 0 }
        },

    };
</script>
<!---------------------------------------------------------------------------->
<style lang="scss" type="text/scss">
</style>
